import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, FormControl,FormErrorMessage ,FormLabel, Input, useToast } from '@chakra-ui/react'
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import * as yup from 'yup';
import { onAuthStateChanged } from "firebase/auth";
import { db,auth } from 'config/firebase';
import { useMediaQuery } from '@mui/material';

const Bank = () => {
  const isMobile=useMediaQuery('(max-width:640px)');
  const toast=useToast();
  const [UserId,setUserId]=useState();

  const [errors,setErrors]=useState({
    bank:{
      accountNumber:"",
      branch:"",
      bankName:"",
      ifscNumber:"",
      bankingName:"",
  }
  });

  // const dataSchema=yup.object().shape({
  //   bank:yup.object().shape({
  //     accountNumber: yup.string().required("This field is Required!"),
  //     branch: yup.string().required("This field is Required!"),
  //     bankName: yup.string().required("This field is Required!"),
  //     ifscNumber: yup.string().required("This field is Required!"),
  //     bankingName:yup.string().required("This field is Required!")
  //   })
  // })

  const [formData,setFormData]=useState({
        bank:{
            accountNumber:"",
            branch:"",
            bankName:"",
            ifscNumber:"",
            bankingName:"",
        }
    }
    )

    useEffect(()=>{
      const unsubscribe=onAuthStateChanged(auth,(user)=>{
        if(user){
          const userid=user.uid;
          setUserId(userid);
        }else{
          console.log("user does not exist in personal section");
        }
      });
  
      return ()=>unsubscribe();
    },[]);

useEffect(()=>{
    getData();
},[UserId]);

const updateDetails=async ()=>{
    try{
        const profileDocRef=collection(db,"userProfiles");
        const q=query(profileDocRef,where("uid","==",UserId));
        const querySnapshot=await getDocs(q);
        console.log(querySnapshot.docs[0]);

        if(!querySnapshot.empty){
            const docId=querySnapshot.docs[0].id;
            const docRef=doc(profileDocRef,docId);
            await updateDoc(docRef,formData);
        }else{
            console.log("User does not exist!")
        }
    }catch(error){
        console.log(error.message);
    }
    
}

const getData=async()=>{
  try{
    const profileDocRef=collection(db,"userProfiles");
    const q=query(profileDocRef,where("uid","==",UserId));
    const querySnapshot=await getDocs(q);
    if(!querySnapshot.empty){
      const data=querySnapshot.docs[0].data();
      console.log("Data:",data);
      const personalData=data.bank;
      setFormData((prevData)=>({
        ...prevData,bank:{
          ...personalData
        }
      }))
    }
  }catch(error){
    console.log(error.message);
    }
  
}

const handleChange=(e)=>{
    const {name,value}=e.target;
    setFormData((prevData)=>({
        ...prevData,bank:{
            ...prevData.bank,
            [name]:value
        }
    }));

    //Validating Specific field
    // dataSchema.validateAt(`bank.${name}`,{...formData,bank:{...formData.bank,[name]:value}}).then(()=>{
    //   setErrors((prevErrors)=>({...prevErrors,bank:{...prevErrors.bank,[name]:''}}));
    // }).catch((err)=>{
    //   setErrors((prevErrors)=>({...prevErrors,bank:{...prevErrors.bank,[name]:err.message}}));
    // })
}

const handleSubmit=async(e)=>{
  e.preventDefault();
    const isFormValid=await formData;
    if(isFormValid){
      updateDetails();
      toast({
        title: "Bank details have been saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top", 
        variant: "solid",
        backgroundColor: "white",
        containerStyle: {
          backgroundColor: "white", 
          border: "1px solid #38A169", 
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: "bold",
          padding: "3px",
          boxShadow: "lg",
          color: "#2D3748", 
          boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
        },
      })
    }else{
      toast({
        title:'Form Submission Failed!',
        description:'There are errors in form.',
        status:'error',
        duration:'2500',
        isClosable:true,
      });
    }
}




  return (
    <Box w="100%" borderWidth='1px' borderRadius='lg' overflow='hidden' padding="2rem">
      <form onSubmit={handleSubmit}>
        <Flex
          mb='5'
          justifyContent="space-between"
          flexDirection={isMobile?"column":"row"}
        >
          <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.bank.accountNumber}>
            <FormLabel htmlFor='accountNumber'>Account Number<span style={{color:"red"}}> *</span></FormLabel>
            <Input
              id="accountNumber"
              name="accountNumber"
              onChange={handleChange}
              value={formData.bank.accountNumber}
              style={{ backgroundColor: "white" }}
              required
            />
            <FormErrorMessage>{errors.bank.accountNumber}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.bank.branch}>
            <FormLabel htmlFor='branch'>Branch Name<span style={{color:"red"}}> *</span></FormLabel>
            <Input
              id="branch"
              name="branch"
              onChange={handleChange}
              value={formData.bank.branch}
              style={{ backgroundColor: "white" }}
              isRequired
            />
            <FormErrorMessage>{errors.bank.branch}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex
          mb='5'
          justifyContent="space-between"
          flexDirection={isMobile?"column":"row"}
        >
          <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.bank.bankName}>
            <FormLabel htmlFor='bank'>Bank Name<span style={{color:"red"}}> *</span></FormLabel>
            <Input
              id="bankName"
              name="bankName"
              onChange={handleChange}
              value={formData.bank.bankName}
              style={{ backgroundColor: "white" }}
              required
            />
            <FormErrorMessage>{errors.bank.bankName}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.bank.ifscNumber}>
            <FormLabel htmlFor='ifscNumber'>IFSC<span style={{color:"red"}}> *</span></FormLabel>
            <Input
              id="ifscNumber"
              name="ifscNumber"
              onChange={handleChange}
              value={formData.bank.ifscNumber}
              style={{ backgroundColor: "white" }}
              required
            />
            <FormErrorMessage>{errors.bank.ifscNumber}</FormErrorMessage>
          </FormControl>
        </Flex>
        <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.bank.bankingName}>
          <FormLabel htmlFor='bankingName'>Banking Name<span style={{color:"red"}}> *</span></FormLabel>
          <Input
            id="bankingName"
            name="bankingName"
            onChange={handleChange}
            value={formData.bank.bankingName}
            style={{ backgroundColor: "white" }}
            required
          />
          <FormErrorMessage>{errors.bank.bankingName}</FormErrorMessage>
        </FormControl>
        <Button
          mt='3'
          colorScheme='blue'
          type="submit"
        >
          Save
        </Button>
      </form>
    </Box>
  )
}

export default Bank

import { Flex, Avatar, Text, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {db} from "config/firebase";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import Meeting from "components/videoCall/Meeting";
const MeetingCard = ({ data }) => {
  console.log(data, "hi");
  const date = data?.meetingDate;
  const partnerId = data?.partnername;
  const time = data?.meetingTime;
  const [isMeetingOpen, setIsMeetingOpen] = useState(false);
  const serviceName = data?.meetingType;
  const [clientName, setClientName] = useState();
  const meetingId = data?.meetingId;
  const [profilePic, setProfilePic] = useState(null);
  console.log(meetingId);
  const MeetingDescription = data?.description;
  const clientId = data?.client;
  const [client, setClient] = useState([]);
  useEffect(() => {
    if (!partnerId) return;

    const fetchPartner = async () => {
      try {
        const meetingsRef = collection(db, "userProfiles");
        const q = query(meetingsRef, where("uid", "==", clientId));
        const querySnapshot = await getDocs(q);

        const clientdata = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setClient(clientdata);
        console.log(clientdata);
      } catch (error) {
        console.error("Error fetching partner:", error);
      }
    };

    fetchPartner();
  }, [partnerId]);

  useEffect(() => {
    const clientName = client?.[0]?.personal?.fullName;
    setClientName(clientName);
    // const profilepic=client?.[0].profileImage;
    // setProfilePic(profilepic);
    //console.log(businessName);
  }, [client]);


  const openMeeting = () => {
    if (!meetingId) {
        console.error("Meeting ID is missing!");
        return;
    }

    const meetingUrl = `/meeting/${meetingId}`; // Adjust the path if needed
    window.open(meetingUrl, "_blank");
};


  // const openMeeting = () => {
  //   if (!meetingId) {
  //     console.error("Meeting ID is missing!");
  //     return;
  //   }

  //   const meetingUrl = `/meeting/${meetingId}`; // Adjust the path if needed
  //   window.open(meetingUrl, "_blank", "width=800,height=600");
  // };

  return (
    <div>

      <div
        style={{
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "16px",
          padding: "16px",
          borderLeft: "4px solid #3B82F6",

        }}
        cursor="pointer" // Makes it look clickable
        onClick={openMeeting}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={profilePic}
            alt="Profile Pic"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              border: "2px solid #E5E7EB",
            }}
          />
          <div style={{ marginLeft: "16px", flexGrow: 1 }}>
            <p style={{ fontSize: "1.5rem", fontWeight: "bold", margin: 0 }}>{clientName}</p>
            <p style={{ fontSize: "0.875rem", color: "#6B7280", margin: 0 }}>{MeetingDescription}</p>

          </div>
        </div>
        <div>
          <p style={{ fontSize: "0.875rem", color: "#4B5563", marginTop: "4px", marginLeft: "40px" }}>
            {MeetingDescription}
          </p>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div style={{ display: "flex", justifyItems: "space-between", alignItems: "center", gap: "32px", marginLeft: "40px" }}>

            <p style={{ fontSize: "0.875rem", fontWeight: "600", color: "#4B5563" }}>{time}</p>
            <p style={{ fontSize: "0.875rem", color: "#6B7280" }}>{date}</p>
          </div>
          <div style={{ display: "flex", gap: "20px", marginLeft: '20px', justifyContent: "space-between", marginTop: "4px" }}>
            <Button
              padding={{ base: "4px 24px", md: "4px 48px" }} // Adjust padding for different screen sizes
              backgroundColor="#D1FAE5"
              color="#065F46"
              borderRadius="20px"
              border="none"
              cursor="pointer"
            >
              Going
            </Button>
            <Button
              padding={{ base: "4px 24px", md: "4px 48px" }} // Adjust padding for different screen sizes
              backgroundColor="#FECACA"
              color="#B91C1C"
              borderRadius="20px"
              border="none"
              cursor="pointer"

            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {isMeetingOpen && <Meeting meetingId={meetingId} onClose={() => setIsMeetingOpen(false)} />}
    </div>
  );
};
export default MeetingCard;
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  Link,
  Progress,
  Text,
} from "@chakra-ui/react";

import closeBtn from "../../project/assets/img/closeBtn.png";
import chat from "../../project/assets/img/chat.png";
import videoCall from "../../project/assets/img/video-call.png";
import internet from "../../project/assets/img/internet.png";
import businessCard from "../../project/assets/img/business-card.png";

import spacing from "../assets/logoDetails/spacing.png";
import prototype from "../assets/logoDetails/prototype.png";
import add from "../assets/logoDetails/add.png";
import colorWheel from "../assets/logoDetails/color-wheel.png";
import flowChart from "../assets/logoDetails/flow-chart.png";
import industrial from "../assets/logoDetails/industrial.png";
import inventory from "../assets/logoDetails/inventory.png";
import oilPrice from "../assets/logoDetails/oil-price.png";
import preferences from "../assets/logoDetails/preferences.png";
import revision from "../assets/logoDetails/revision.png";
import schedule from "../assets/logoDetails/schedule.png";
import timetable from "../assets/logoDetails/timetable.png";
import voice from "../assets/logoDetails/voice.png";
import branding from "../assets/logoDetails/branding.png";
import cadbery from "../assets/logoDetails/cadbery.png";
import adidas from "../assets/logoDetails/adidas.png";

function LogoProjectDetails({ openModal }) {
  return (
    <Flex
      flexDirection={"column"}
      rowGap={{ sm: ".5rem", md: ".7rem" }}
      borderRadius={"20px"}
      padding={"20px"}
      color={"#455A64"}
      fontFamily={"Cabin,sans-serif"}
      fontSize={{ sm: "smaller", md: "large" }}
      boxShadow={"4px 4px 4px 5px rgba(0, 0, 0, 0.25)"}
    >
      <Flex justifyContent={"space-between"}>
        <Text
          fontWeight={"bold"}
          fontSize={{ sm: "1.150rem", md: "1.8rem" }}
          color="#1B2559"
          paddingX={"8px"}
        >
          Logo Design
        </Text>
        <Button
          bg={"transparent"}
          size={{ sm: "xs", md: "sm" }}
          onClick={openModal}
        >
          <Image
            src={closeBtn}
            width={{ sm: "15px", md: "20px" }}
            height={{ sm: "15px", md: "20px" }}
          />
        </Button>
      </Flex>
      <Text
        fontSize={{ sm: "1rem", md: "1.5rem" }}
        color={"#1B2559"}
        paddingX={"8px"}
      >
        Details
      </Text>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
        // fontSize={{sm:'smaller',md:"large"}}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Startup Name
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={businessCard}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Bizowl</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Website
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={internet}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>www.bizowl.com </Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Type of Logo
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={spacing}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Wordmark, Modern</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Style of Logo
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={prototype}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Minimilist, Modern </Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Concept
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={inventory}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>3</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Revisions
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={revision}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>3</Text>
          </Flex>
        </Flex>
      </Grid>
      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            TAT
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={timetable}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>4 days</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Date
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={schedule}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>24 jan 2025, 5:05PM </Text>
          </Flex>
        </Flex>
      </Grid>

      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Text
          fontSize={{ sm: "small", md: "medium" }}
          color={"rgba(27, 37, 89, 0.8)"}
        >
          Pricing
        </Text>
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={oilPrice}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text>2-5K</Text>
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={add}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Additional Branding Assets
          </Text>
        </Flex>
        <Text w={"50%"}>
          Letterhead, Branding Guidelines, Font, Visiting Card
        </Text>
      </Flex>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Business Model
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={flowChart}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Wordmark, Modern</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Industry Type
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={industrial}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Minimilist, Modern </Text>
          </Flex>
        </Flex>
      </Grid>
      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Text
          fontSize={{ sm: "small", md: "medium" }}
          color={"rgba(27, 37, 89, 0.8)"}
        >
          Color Scheme
        </Text>
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={colorWheel}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text>3</Text>
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        gap={".8rem"}
        padding={".4rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={preferences}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Logo Preference
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={{ sm: "1rem", md: "2rem" }}>
          <Image
            src={cadbery}
            width={{ sm: "20px", md: "35px" }}
            height={{ sm: "20px", md: "35px" }}
          />
          <Image
            src={adidas}
            width={{ sm: "20px", md: "35px" }}
            height={{ sm: "20px", md: "35px" }}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={".5rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={voice}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Project Requirements
          </Text>
        </Flex>

        <Text>
          We are facing a lot of problem in our organic growth and we want
          someone to improve it. This is our requirement.{" "}
        </Text>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={".5rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={branding}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Branding Proposition
          </Text>
        </Flex>
        <Text>
          We are facing a lot of problem in our organic growth and we want
          someone to improve it. This is our requirement.{" "}
        </Text>
      </Flex>

      <Flex
        flexDir={"column"}
        fontSize={{ sm: "small", md: "medium" }}
        gap={{ sm: ".2rem", md: ".7rem" }}
        paddingY={"1rem"}
      >
        <Box color={"rgba(27, 37, 89, 0.8)"} padding={".5rem"}>
          <Text>Communication</Text>
          <Flex gap=".5rem" mt={".3rem"}>
            <Image
              src={chat}
              width={{ sm: "20px", md: "30px" }}
              height={{ sm: "20px", md: "30px" }}
            />
            <Image
              src={videoCall}
              width={{ sm: "20px", md: "30px" }}
              height={{ sm: "20px", md: "30px" }}
            />
          </Flex>
        </Box>

        <Flex
          flexDirection="column"
          color={"rgba(27, 37, 89, 0.8)"}
          padding={".5rem"}
        >
          <Text color={"rgba(27, 37, 89, 0.8)"}>Progress</Text>
          <Box position="relative">
            <Progress
              mt={".3rem"}
              w="100%"
              height={{ sm: ".6rem", md: ".7rem" }}
              value={90}
            />
            <Text
              position="absolute"
              transform="translate(10%,-3px)"
              right="0"
              p="0.3rem"
              color={"black"}
            >
              90%
            </Text>
          </Box>
        </Flex>

        <Flex gap={".4rem"} padding={".5rem"} alignItems={"center"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Update Progress</Text>
          <Input
            type="number"
            width="20px"
            height="20px"
            fontSize="sm"
            textAlign="center"
            padding="0"
            borderRadius="4px"
            border="1px solid "
            borderColor={"black"}
            _focus={{ borderColor: "blue.500", boxShadow: "0 0 2px blue.500" }}
          />
          <span>%</span>
        </Flex>

        <Box color={"rgba(27, 37, 89, 0.8)"} padding={".5rem"}>
          <Text>Payment Structure</Text>
          <Flex mt={".3rem"}>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={{ sm: "6rem", md: "8rem" }}
              height={{ sm: "1.4rem", md: "2rem" }}
              bg={"#fff"}
              color={"yellow.500"}
              borderTopStartRadius={".9rem"}
              borderBottomStartRadius={".9rem"}
            >
              50
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={{ sm: "6rem", md: "8rem" }}
              height={{ sm: "1.4rem", md: "2rem" }}
              bg={"#b5fffd"}
              color={"#06d2a6"}
              borderTopEndRadius={".9rem"}
              borderBottomEndRadius={".9rem"}
            >
              50
            </Flex>
          </Flex>
        </Box>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Bidding Details</Text>
          <Button
            bg={"#407bff"}
            color={"#fff"}
            variant="solid"
            w={{ sm: "6rem", md: "7rem" }}
            size={"sm"}
          >
            Details
          </Button>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Payment Status</Text>
          <Flex
            justifyContent={"space-between"}
            gap={"1rem"}
            alignItems={"center"}
          >
            <Flex gap={{ sm: "2rem", md: "8rem" }} alignItems={"center"}>
              <Button
                // bg={"#65c756"}
                bg={"#407bff"}
                color={"#fff"}
                w={{ sm: "6rem", md: "7rem" }}
                size={"sm"}
              >
                Received
              </Button>
              <Button
                bg={"#f28f8f"}
                color={"#fff"}
                w={{ sm: "6rem", md: "7rem" }}
                size={"sm"}
              >
                Pending
              </Button>
            </Flex>
            <Link color={"#407bff"}>Need Help?</Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LogoProjectDetails;

import React, { useEffect, useState } from "react";
import logo from "../../../assets/img/dashboards/logo-of-BIZOWL--3x.png";

// Chakra imports
import { Flex } from "@chakra-ui/react";

// Custom components

import { FaAngleRight, } from "react-icons/fa6";
import { CloseIcon } from "@chakra-ui/icons";
import { auth } from "config/firebase";
import { db } from "config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import useUserDisplayName from "components/hooks/useUserDisplayName";
import { collection, getDocs, query, where } from "firebase/firestore";
import maleProfilePic from "../../../views/admin/profile/assets/maleProfileImage.png";
import femaleProfilePic from "../../../views/admin/profile/assets/femaleProfileImage.png";
import userIcon from "../../../views/admin/profile/assets/4957136.jpg";

function extractEmailUsername(username) {
  // Check if the username contains '@'
  if (username.includes("@")) {
    // Split the string at '@' and take the first part
    return username.split("@")[0];
  }
  // Return the original username if it doesn't contain '@'
  return username;
}

function capitalizeFirstLetter(string) {
  if (typeof string === "string") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string; // Return original string if not a string type
}

export function Brand({ setIsCollapse }) {
  const { displayName, isLoading } = useUserDisplayName(db, auth);
  const [profilePicture, setProfilePicture] = useState("");
  const [UserId, setUserId] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userid = user.uid;
        setUserId(userid);
      } else {
        console.log("user does not exist in personal section");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(()=>{
    fetchUserData();
  },[profilePicture]);

  const fetchUserData = async () => {
    try {
      if (UserId) {
        const ProfileDocRef = collection(db, "userProfiles");
        const q = query(ProfileDocRef, where("uid", "==", UserId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          if (data?.profileImage) {
            setProfilePicture(data?.profileImage);
          } else {
            setProfilePicture(maleProfilePic);
          }
        } else {
          console.log("User does not exist!");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [UserId]);
  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      {/* Profile and UserName  */}

      <Flex align="center" direction="column">
        <a href="https://www.bizzowl.com">
          <img src={logo} alt="Bizowl-logo" height="26px" width="175px" />
        </a>
      </Flex>

      <button
        style={{
          height: "6px",
          width: "6px",
          color: "Black",
          fontSize: "25px",
          position: "absolute",
          top: "-5px",
          right: "22px",
          transition: "all 0.2s ease-out",
        }}
      >
        <CloseIcon bg={"white"} borderRadius={"50%"} p={"4px"} onClick={() => setIsCollapse(true)} />
      </button>

      <Flex align="center" direction="column" padding="15px" mt="4">
        <Flex
          direction={"column"}
          className="profile-pic"
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: ".4rem",
          }}
        >
          <div className="image">
            <div
              style={{
                borderRadius: "100%",
                height: "90px",
                width: "90px",
                overflow: "hidden",
              }}
            >
              <img
                src={profilePicture}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
          <p style={{ fontSize: "15px" }}>{displayName}</p>
        </Flex>
      </Flex>
    </>
  );
}

export default Brand;

import { useEffect } from "react";

const SupportRedirect = () => {
  useEffect(() => {
    window.location.href = "https://www.bizzowl.com/support";
  }, []);

  return null; // No UI needed, just redirect
};

export default SupportRedirect;

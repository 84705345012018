import { Flex, Icon, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { db, auth } from "config/firebase";
import { collection, onSnapshot, query, where, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { FaRegBell } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import menuIcon from "../../assets/img/dashboards/menuIcon.svg";
import useUserDisplayName from "components/hooks/useUserDisplayName";
import maleProfilePic from "../../views/partner/partnerProfile/assets/maleProfileImage.png"
import femaleProfilePic from "../../views/partner/partnerProfile/assets/femaleProfileImage.png"
import userIcon from "../../views/partner/partnerProfile/assets/4957136.jpg"


const Header = (props) => {
  const [partnerProfile, setPartnerProfile] = useState({});
  const [unSubscribe, setUnSubscribe] = useState(null);
  const [UserId, setUserId] = useState();
  const { displayName, isLoading } = useUserDisplayName(db, auth);
  const searchBarDisplay = useBreakpointValue({ base: "none", md: "block" });

  useEffect(() => {
    return () => {
      if (unSubscribe) {
        unSubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userid = user.uid;
        setUserId(userid);
      } else {
        console.log("user does not exist in personal section");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getPartnerProfile();
  }, [UserId]);

  const getPartnerProfile = async () => {
    try {
      const queryForGetPartner = query(
        collection(db, "userProfiles"),
        where("uid", "==", UserId)
      );
      const subScribe = onSnapshot(queryForGetPartner, (snapshot) => {
        const partnerData = snapshot.docs[0]?.data();
        if (partnerData.profileImage) {
          setPartnerProfile(partnerData.profileImage);
        } else {
          setPartnerProfile(maleProfilePic);
        }
      });
      setUnSubscribe(() => subScribe);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Flex position={{ base: "absolute", md: "unset" }} 
    top={{ base: "0", md: "unset" }} 
    left={{ base: "0", md: "unset" }}  alignItems="center" p={{base: 0, md: 4}} boxShadow={{ base: "0px 2px 0px rgba(0, 0, 0, 0.1)", md: "none" }} justifyContent="space-between" h={{base: "55px", md: "100%"}}  w="100%" px={{base: 2, md: 4}}>
  {/* Left Section - Brand and Menu Icon */}
  <Flex alignItems="center">
    <Image
      display={{ base: "block", md: "none" }}
      src={menuIcon}
      onClick={props.onOpen}
      boxSize="2.6rem"
      cursor="pointer"
      mt=".6rem"
    />
    <Text fontWeight="bold" ml={{base: 1.1, md: 2}}  fontSize={{ base: "lg", md: "xl" }}>
      {props.brandText}
    </Text>
  </Flex>

  {/* Right Section - SearchBar, Icons, and Profile */}
  <Flex alignItems="center" gap={{ base: 2, md: 4 }}>
    <SearchBar
      display={searchBarDisplay}
    />

    <Flex fontSize="lg" alignItems="center" gap={3}>
      <Icon as={MdOutlineEmail} cursor="pointer" opacity={{base: "0.8", md: "1"}} boxSize={{ base: "1.29rem", md: "1rem" }} />
      <Icon as={FaRegBell} cursor="pointer" opacity={{base: "0.8", md: "1"}} boxSize={{ base: "1.2rem", md: "1rem" }} />
    </Flex>

    <Flex alignItems="center" gap={2}>
      <Image
        boxSize={{ base: "1.7rem", md: "2rem" }}
        borderRadius="full"
        src={partnerProfile}
        alt="Profile Picture"
      />
      <Flex flexDirection="column" display={{ base: "none", md: "flex" }}>
        <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
          {displayName}
        </Text>
        <Text fontSize="sm" color="gray.600">CRB</Text>
      </Flex>
    </Flex>
  </Flex>
</Flex>

  );
};

export default Header;

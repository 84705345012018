import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Select, Text, useToast } from '@chakra-ui/react'
import { useMediaQuery } from '@mui/material';
import { db,auth } from 'config/firebase';
import { collection,doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import * as yup from "yup";


const BusinessInfo = () => {

  const [partnerId,setPartnerId]=useState("")
  const toast = useToast();
  const isMobile=useMediaQuery('(max-width:640px)')
  const [UserId,setUserId]=useState();
  const [errors,setErrors]=useState({
    businessInfo:{
      businessName:"",
      gstNumber:"",
      experience:"",
      businessStage:"",
      numberOfEmployees:"",
      businessIndustry:"",
      reqService:""
    }
  });
  
  const [formData,setFormData]=useState({
    businessInfo:{
      businessName:"",
      gstNumber:"",
      experience:"",
      businessStage:"",
      numberOfEmployees:"",
      businessIndustry:"",
      reqService:"",
      budget: "", // Added budget field
      commission: "10"
    }
  });

  // const dataSchema=yup.object().shape({
  //   businessInfo:yup.object().shape({
  //     businessName:yup.string().required('This field is required!'),
  //     gstNumber:yup.string().required('This field is required!'),
  //     experience:yup.string().required('This field is required!'),
  //     businessStage:yup.string().required('This field is required!'),
  //     numberOfEmployees:yup.string().required('This field is required!'),
  //     businessIndustry:yup.string().required('This field is required!'),
  //     reqService:yup.string().required('This field is required!')
  //   })
  // });


    useEffect(() => {
      const unsubscribe=onAuthStateChanged(auth,(user)=>{
        if(user){
          const userid=user.uid;
          setUserId(userid);
        }else{
          console.log("user does not exist in personal section");
        }
      });
  
      return ()=>unsubscribe();
  },[]);

  useEffect(()=>{
      getData();
  },[UserId]);

  const updateDetails=async ()=>{
      try{
          const profileDocRef=collection(db,"userProfiles");
          const q=query(profileDocRef,where("uid","==",UserId));
          const querySnapshot=await getDocs(q);
  
          if(!querySnapshot.empty){
            const docId=querySnapshot.docs[0].id;
            const docRef=doc(profileDocRef,docId);
            await updateDoc(docRef,formData);
            toast({
              title: "Business details have been saved.",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top", 
              variant: "solid",
              backgroundColor: "white",
              containerStyle: {
                backgroundColor: "white", 
                border: "1px solid #38A169", 
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "bold",
                padding: "3px",
                boxShadow: "lg",
                color: "#2D3748", 
                boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
              },
            })
        }else{
            console.log("User does not exist!")
        }
      }catch(error){
        toast({
          title:'Form Submission Failed!',
          description:'There are errors in form.',
          status:'error',
          duration:'2500',
          isClosable:true,
        });
      }
      
  }

  const getData=async()=>{
    try{
      if(!UserId){
        console.log("User Id does not exist!")
      }
      else{
        const profileDocRef=collection(db,"userProfiles");
        const q=query(profileDocRef,where("uid","==",UserId));
        const querySnapshot=await getDocs(q);
        if(!querySnapshot.empty){
          const data=querySnapshot.docs[0].data();
          const businessInfoData=data.businessInfo;
          setFormData((prevData)=>({
            ...prevData,businessInfo:{
              ...businessInfoData
            }
          }))
        }
      } 
    }catch(error){
      console.log(error.message);
      }
  }

  const handleChange=(e)=>{
      const {name,value}=e.target;
      setFormData((prevData)=>({
          ...prevData,businessInfo:{
              ...prevData.businessInfo,
              [name]:value
          }
      }));

      // dataSchema.validateAt(`businessInfo.${name}`,{...formData,businessInfo:{...formData.businessInfo,[name]:value}}).then(()=>{
      //   setErrors((prevErrors)=>({...prevErrors,businessInfo:{...prevErrors.businessInfo,[name]:''}}));
      // }).catch((err)=>{
      //   setErrors((prevErrors)=>({...prevErrors,businessInfo:{...prevErrors.businessInfo,[name]:err.message}}));
      // });
  }

  const handleSubmit=(e)=>{
      e.preventDefault();
      console.log("formData:",formData);
      updateDetails();
  }



  return (
    <Box w="100%" borderWidth='1px' borderRadius='lg' overflow='hidden' padding="2rem">
      <form onSubmit={handleSubmit}>
        <Flex
          mb='5'
          justifyContent="space-between"
          flexDirection={isMobile?"column":"row"}
        >
          <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.businessInfo.businessName}>
            <FormLabel htmlFor='businessName'>Business Name <span style={{color:"red"}}>*</span></FormLabel>
            <Input
              id="businessName"
              name="businessName"
              type="text"
              onChange={handleChange}
              value={formData.businessInfo.businessName}
              style={{ backgroundColor: "white" }}
              required
            />
            <FormErrorMessage>{errors.businessInfo.businessName}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.businessInfo.gstNumber}>
            <FormLabel htmlFor='gstNumber'>GST Number</FormLabel>
            <Input
              id="gstNumber"
              name="gstNumber"
              type="text"
              onChange={handleChange}
              value={formData.businessInfo.gstNumber}
              style={{ backgroundColor: "white" }}
            />
            <FormErrorMessage>{errors.businessInfo.gstNumber}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex
          mb='5'
          justifyContent="space-between"
          flexDirection={isMobile?"column":"row"}
        >
          <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.businessInfo.experience}>
            <FormLabel htmlFor='experience'>Experience (in Years) <span style={{color:"red"}}>*</span></FormLabel>
            <Input
              id="experience"
              name="experience"
              type='number'
              style={{ backgroundColor: "white" }}
              onChange={handleChange}
              value={formData.businessInfo.experience}
              required
            />
            <FormErrorMessage>{errors.businessInfo.experience}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.businessInfo.numberOfEmployees}>
            <FormLabel htmlFor='numberOfEmployees'>No. of Employees</FormLabel>
            <Input
              id="numberOfEmployees"
              name="numberOfEmployees"
              type="number"
              appearance={'none'}
              style={{ backgroundColor: "white" }}
              onChange={handleChange}
              value={formData.businessInfo.numberOfEmployees}
            />
            <FormErrorMessage>{errors.businessInfo.numberOfEmployees}</FormErrorMessage>
          </FormControl>
        </Flex>
        <FormLabel htmlFor='budget' mb='2'>Are you comfortable working with low budget or ticket clients ( Startups Mainly ) ?<span style={{color:"red"}}> *</span></FormLabel>
        <Select
          mb='4'
          id="budget"
          name="budget"
          required
          backgroundColor="white"
          onChange={handleChange}
          value={formData.businessInfo.budget}
          
        >
          <option value='True'>Yes</option>
          <option value='False'>No</option>

          
        </Select>
        <FormLabel htmlFor='commission' mb='2'>What commission rate are you comfortable with disclosing for the work you receive through our platform?</FormLabel>
        <Flex justifyContent="space-between" width="235px">
          <p>10%</p>
          <p>{formData.businessInfo.commission}%</p>
          <p>50%</p>
        </Flex>
        <div ms='2rem' style={{margin:'1rem'}}>
        <input type="range" className="form-range" min="10" max="50" id="commission" name="commission" value={formData.businessInfo.commission} onChange={handleChange} style={{width:"200px"}}/>
        </div>
        <Button
          mt='5'
          colorScheme='blue'
          type="submit"
        >
          Save
        </Button>
      </form>
    </Box>
  )
}

export default BusinessInfo

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Img,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import EventCard from "components/EventCard/EventCard";
import MeetingCard from "components/MeetingCard/MeetingCard";
import MiniCalendar from "components/calendar/MiniCalendar";
import { HSeparator, VSeparator } from "components/separator/Separator";
import { auth, db } from "config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import QueriesTable from "../inbox/components/QueriesTable";
import ProfilePic from "./assets/Ellipse 658.png";
import VectorImg from "./assets/Vector.png";
import AuthenticImg from "./assets/authentic.png";
import ExclusivenessImg from "./assets/exclusiveness.png";
import FlexibleImg from "./assets/flexible.png";
import CustomCard from "./components/CustomCard";
import { Cards, ChatData, DashboardData, EventData, MeetingData } from "./data/DashboardData";
const PartnerDashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [currentTab, setCurrentTab] = useState("meetings");
  const [eventData, setEventData] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [meetingData, setMeetingData] = useState([]);
  const [userId, setUserId]=useState(null);
  const [meetings, setMeetings]= useState([]);
  const [pastMeetings, setPastMeetings]=useState([]);
  const [upcomingMeetings, setUpcomingMeetings]= useState([]);
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    setActiveTab("upcoming");
    setDashboardData(DashboardData);
    setCardData(Cards);
    setChatData(ChatData);
    setEventData(EventData);
    setMeetingData(MeetingData);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userid = user.uid;
        setUserId(userid);
        console.log(userid);
      } else {
        console.log("user does not exist in personal section");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!userId) return;

    // Query Firestore for meetings where userId is the client
    const fetchMeetings = async () => {
      try {
        const meetingsRef = collection(db, "videocallscheduling");
        const q = query(meetingsRef, where("partnername", "==", userId));
        const querySnapshot = await getDocs(q);

        const meetingsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMeetings(meetingsList);
        console.log(userId);
        console.log(meetingsList);


        const pastMeetingsList = [];
        const upcomingMeetingsList = [];

        meetingsList.forEach((meeting) => {
          if (!meeting.meetingDate || !meeting.meetingTime || 
              typeof meeting.meetingDate !== "string" || typeof meeting.meetingTime !== "string") {
            console.error("Invalid or missing date/time:", meeting.meetingDate, meeting.meetingTime);
            return; // Skip invalid entries
          }
        
          // Combine date and time properly
          const meetingDateTimeString = `${meeting.meetingDate}T${meeting.meetingTime}`;
          const meetingDateTime = new Date(meetingDateTimeString);
        
          if (isNaN(meetingDateTime.getTime())) {
            console.error("Invalid datetime format:", meetingDateTimeString);
            return; // Skip invalid dates
          }
        
          // Get the current date and time
          const now = new Date();
        
          console.log("Meeting Time:", meetingDateTime, "Current Time:", now);
        
          // Categorize meeting
          if (meetingDateTime < now) {
            pastMeetingsList.push(meeting);
          } else {
            upcomingMeetingsList.push(meeting);
          }
        });
        
        console.log(pastMeetingsList);
        console.log(upcomingMeetingsList);
        setPastMeetings(pastMeetingsList);
        setUpcomingMeetings(upcomingMeetingsList);


      } catch (error) {
        console.error("Error fetching meetings:", error);
      }
    };

    fetchMeetings();
  }, [userId]);


  return (
    <>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
        mb="2rem"
        mt={{ base: "4rem", md: "unset" }}
      >
        <Flex
          p="1rem"
          alignItems="center"
          justifyContent="center"
          width={{ base: "100%", md: "63%" }}
          bgColor="#E1F2EF"
          borderRadius="1rem"
          mb={{ base: "1rem", md: "0rem" }}
        >
          <Flex w="80%" flexDirection="column">
            <Text fontSize="1.5rem" fontWeight="bold" color="#1B2559">
              Welcome back, Partner
            </Text>
            <Text fontSize="sm">
              Be the part of something good. Get more customers without the
              hassles of finding new ones. We bring and you deliver.
            </Text>
          </Flex>
          <Flex>
            <Img src={VectorImg} alt="Vector Img" w="10rem" h="10rem" />
          </Flex>
        </Flex>
        <Flex
          p={{ base: "1rem 0rem 1rem 1rem", md: "1rem 0rem 0rem 1rem" }}
          //alignItems="center"
          borderRadius="1rem"
          flexDirection="column"
          width={{ base: "100%", md: "33%" }}
          boxShadow="0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)"
        >
          <div
            style={{
              color: "#1B2559",
              fontSize: "1.5rem",
              fontWeight: "bold",
              bgColor: "#F7F8FD",
            }}
          >
            <h1>Total Earnings</h1>
            <p>0 INR</p>
          </div>
          <Text w="max-content" fontSize="sm" cursor="pointer">
            View History
          </Text>
          <HSeparator />
        </Flex>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="2rem">
        <Text fontSize="1.5rem" fontWeight="bold">
          Analytics
        </Text>
        <Flex
          width="10rem"
          height="5vh"
          alignItems="center"
          justifyContent="center"
          bgColor="#D7E9FD"
          marginBottom="1rem"
          borderRadius="1rem"
        >
          <Select
            placeholder="Select option"
            colorScheme="#1C6ED059"
            border="none"
            borderRadius="2rem"
            cursor="pointer"
            iconColor="#FFFFFF"
            icon={
              <Icon
                as={ChevronDownIcon}
                backgroundColor="#1C6ED0"
                height="55%"
                borderRadius="2rem"
              />
            }
            style={{
              backgroundColor: "#D7E9FD",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <option value="7days">Last 7 days</option>
            <option value="1month">Last month</option>
            <option value="1year">Last year</option>
          </Select>
        </Flex>
      </Flex>
      <Flex flexWrap="wrap" justifyContent="space-between" mb="3rem">
        {cardData.map((card, index) => (
          <CustomCard key={index} card={card} />
        ))}
      </Flex>
      <Flex
        flexDirection="column"
        height="max-content"
        alignItems="center"
        color="#37474F"
        padding="1rem"
        boxShadow="0.0625rem 0.0625rem 0.25rem 0rem rgba(64, 123, 255, 0.43) inset"
        borderRadius="2rem"
      >
        <Text fontWeight="bold" fontSize="1.7rem" marginBottom="2.5rem">
          Boost Your Visiblity
        </Text>
        <Flex flexDirection={{ base: "column", md: "row" }}>
          <Flex flexDirection="column" alignItems="center">
            <Text fontWeight="bold" fontSize="1.7rem">
              Exclusiveness
            </Text>
            <Img
              src={ExclusivenessImg}
              alt="ExclusivenessImg"
              style={{ width: "10rem" }}
            />
            <Text m="1rem">
              Always try to give exclusive price to user requesting their
              service on Bizowl. This will get you more clients,.
            </Text>
          </Flex>
          <VSeparator display={{ base: "none", md: "block" }} />{" "}
          {/* Only visible on medium and larger screens */}
          <HSeparator display={{ base: "block", md: "none" }} />{" "}
          {/* Only visible on small screens */}
          <Flex flexDirection="column" alignItems="center">
            <Text fontWeight="bold" fontSize="1.7rem">
              Authentic
            </Text>
            <Img
              src={AuthenticImg}
              alt="AuthenticImg"
              style={{ width: "7rem" }}
            />
            <Text m="1rem">
              Try to be more authentic and believe in provide value. Provide
              your best work, top quality work.
            </Text>
          </Flex>
          <VSeparator display={{ base: "none", md: "block" }} />{" "}
          {/* Only visible on medium and larger screens */}
          <HSeparator display={{ base: "block", md: "none" }} />{" "}
          {/* Only visible on small screens */}
          <Flex flexDirection="column" alignItems="center">
            <Text fontWeight="bold" fontSize="1.7rem">
              Flexible
            </Text>
            <Img
              src={FlexibleImg}
              alt="FlexibleImg"
              style={{ width: "7rem" }}
            />
            <Text m="1rem">
              People who are ideal customer of bizowl are early stage startup,
              Low budget client. Be flexible.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* <MiniCalendar h='100%' minW='100%' selectRange={false} /> */}
      <Flex
        mt="3rem"
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box
          backgroundColor="#FFFFFF"
          width={{ base: "100%", md: "55%" }}
          height="110vh"
          borderRadius="0.6rem"
        >
          <Flex
            pl="1.5rem"
            pr="1.5rem"
            fontWeight="bold"
            justifyContent="space-between"
            alignItems="center"
            height="4rem"
            backgroundColor="#E1F2EF"
            borderRadius="0.6rem 0.6rem 0 0"
          >
            <Text>Inbox</Text>
            <Text color={"#5388FF"}>View All</Text>
          </Flex>
          {/* {chatData.map((item, index) => {
            return (
              <Box key={index}>
                <Flex p="1.5rem">
                  <Avatar src={ProfilePic} alt="Profile Pic" />
                  <Flex ml="1rem" flexDirection="column">
                    <Text fontWeight="bold">{item?.clientName}</Text>
                    <Text fontSize="sm">{item?.message}</Text>
                  </Flex>
                </Flex>
                <HSeparator />
              </Box>
            );
          })} */}
        </Box>
        <Box
          backgroundColor={currentTab === "meetings" ? "#FFFFFF" : "#F0F0F0"}
          width={{ base: "100%", md: "40%" }}
          height="110vh"
          borderRadius="0.6rem"
          position="relative"
        >
          <Tabs
            position="absolute"
            variant="unstyled"
            width="100%"
            pl="1rem"
            pr="1rem"
            height="4rem"
            backgroundColor={currentTab === "meetings" ? "#E1F2EF" : "#D9D9D9"}
            borderRadius="0.6rem 0.6rem 0 0"
          >
            <TabList
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingTop="1rem"
            >
              <Tab
                id="meetings"
                fontWeight="bold"
                onClick={() => setCurrentTab("meetings")}
              >
                Meetings
              </Tab>
              <Tab
                id="calendar"
                fontWeight="bold"
                onClick={() => setCurrentTab("calendar")}
              >
                Calendar
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {/* {dashboardData.map((item, index) => (
                  <Box
                    key={index}
                    p="1rem"
                    mt="1.5rem"
                    height="auto"
                    borderRadius="1rem"
                  ></Box>
                ))}
                <Tabs>
                  <TabList>
                    <Tab>
                      <Button border="1px solid black">Upcoming</Button>
                    </Tab>
                    <Tab>
                      <Button border="1px solid black">Past</Button>
                    </Tab>
                  </TabList>
                  
                  <TabPanels>

                  </TabPanels>
                </Tabs> */}
                <div style={{ width: "100%", maxWidth: "640px", margin: "0 auto" }}>
                  {/* Tab Navigation */}
                  <div  style={{ display: "flex", justifyContent: "center", gap: "50px", marginBottom: "20px",  }}>
                    <button
                      style={{
                        padding: "8px 32px",
                        borderRadius: "20px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        outline: "none",
                        backgroundColor: activeTab === "upcoming" ? "#3B82F6" : "#E5E7EB",
                        color: activeTab === "upcoming" ? "#FFFFFF" : "#374151",
                        cursor: "pointer",
                        border: "none",
                      }}
                      onClick={() => handleTabChange("upcoming")}
                    >
                      Upcoming
                    </button>

                    <button
                      style={{
                        padding: "8px 32px",
                        borderRadius: "20px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        outline: "none",
                        backgroundColor: activeTab === "past" ? "#3B82F6" : "#E5E7EB",
                        color: activeTab === "past" ? "#FFFFFF" : "#374151",
                        cursor: "pointer",
                        border: "none",
                      }}
                      onClick={() => handleTabChange("past")}
                    >
                      Past
                    </button>
                  </div>
                  {activeTab === "upcoming" && (
                    <Box mt="16px" display="flex" flexDirection="column" gap="16px" maxH="90vh"
                      p="1rem"
                      overflowY="auto"
                      css={{
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        '&': {
                          msOverflowStyle: 'none',
                          scrollbarWidth: 'none',
                        },
                      }}>

                      {upcomingMeetings.map((item, index) => (
                        <Box
                          key={index}
                          _hover={{
                            transform: 'scale(1.05)',  
                            boxShadow: 'none',  
                            borderRight: '4px solid #3B82F6', 
                            borderRadius:'16px',
                            cursor: 'pointer',  
                          }}
                          transition="all 0.3s ease">
                          <MeetingCard
                            data={item}
                          />
                        </Box>
                      ))}


                    </Box>
                  )}
                  {activeTab === "past" && (
                    <Box mt="16px" display="flex" flexDirection="column" gap="16px" maxH="90vh"
                      p="1rem"
                      overflowY="auto"
                      css={{
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        '&': {
                          msOverflowStyle: 'none',
                          scrollbarWidth: 'none',
                        },
                      }}>

                      {pastMeetings.map((item, index) => (
                        <Box
                        key={index}
                        _hover={{
                          transform: 'scale(1.05)',  
                            boxShadow: 'none',  
                            borderRight: '4px solid #3B82F6', 
                            borderRadius:'16px',
                            cursor: 'pointer', 
                        }}
                        transition="all 0.3s ease">
                        <MeetingCard
                          data={item}
                        />
                      </Box>
                      ))}


                    </Box>
                  )}

                </div>

              </TabPanel>
              <TabPanel marginTop="1rem">
                <MiniCalendar h="100%" minW="100%" selectRange={false} />
                <Box mt="5">
                  <Flex fontSize="1.2rem" fontWeight="bold" mt="2" ml="2">Todays Appointment ( 2 )</Flex>
                  <Box
                    maxH={{ base: "40vh", md: "40vh", lg: "45vh" }}
                    p="1rem"
                    overflowY="auto"
                    css={{
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                      '&': {
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                      },
                    }}
                  >
                    {eventData.map((item, index) => (
                      <Box
                        key={index}
                        backgroundColor="#F7FCFB"
                        borderRadius="20px"
                        marginBottom="10px"
                        border="none"
                      >
                        <EventCard
                          clientName={item?.clientName}
                          serviceName={item?.serviceName}
                          time={item?.time}
                          profilePic={item?.profilePic || ProfilePic}
                        />
                      </Box>
                    ))}
                  </Box>

                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
      <Box mt={"1rem"}>
        <QueriesTable />
      </Box>
    </>
  );
};

export default PartnerDashboard;

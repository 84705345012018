import React, { useState, useEffect } from "react";
import { db } from "config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import VideoCall from "./videoCall";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const Meeting = () => {
  const { meetingId } = useParams();
  const [meetingData, setMeetingData] = useState(null);
  console.log(meetingId);
  useEffect(() => {
    console.log(meetingId,"hello");
    const fetchMeetingDetails = async () => {
      try {
        if (!meetingId) return;
    
        console.log("Fetching meeting details for:", meetingId);
    
        // Reference to the collection
        const meetingRef = collection(db, "videocallscheduling");
    
        // Query where "meetingId" field matches the provided meetingId
        const q = query(meetingRef, where("meetingId", "==", meetingId));
        
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
          const meetingData = querySnapshot.docs[0].data();
          setMeetingData(meetingData);
          console.log("Meeting Data:", meetingData);
        } else {
          console.log("No such meeting exists!");
        }
      } catch (error) {
        console.error("Error fetching meeting:", error);
      }
    };

    fetchMeetingDetails();
  }, [meetingId]);

  if (!meetingData) return <p>Loading Meeting...</p>;
  

  return (
    <>
      <VideoCall
        appId={meetingData?.appID}
        channelName={meetingData?.meetingId}
        token={meetingData?.agoraToken?.[meetingData.partnername] || ""}
        uid={meetingData?.partnername}
      />
      <VideoCall
        appId={meetingData?.appID}
        channelName={meetingData?.meetingId}
        token={meetingData?.agoraToken?.["999999"] || ""} // Token for bot user
        uid="999999" // Fixed UID for Bot
        hidden
      />
    </>
  );
};

export default Meeting;

import Card from "components/card/Card";
import {
  Text,
} from "@chakra-ui/react";

import React from "react";
import Line19 from "../assets/img/Line 19.png";

const CustomCard = ({ card }) => {
  return (
    <Card
    width={{base:"47%",md:"30%"}} height={{base: "unset", md: "130px"}} borderRadius={{base: "18px", md: "25px"}} bgColor={card?.bgColor} style={{boxShadow:"#E5A8CC", marginBottom:"2rem",boxShadow:"2px 4px 14px 0px #E5A8CC"}}
    >
      <Text color="#1B2559" fontWeight="bold" fontSize={{base: ".87rem", md: "1.25rem"}} >{card?.project}</Text>
                  <Text color="#1B2559" fontWeight="bold" fontSize={{base: ".87rem", md: "1.25rem"}}>{card?.number}</Text>
      <img src={Line19} alt="LineImg" />

    </Card>
  );
};

export default CustomCard;

import { Box, Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react';
import { subCategoryServicesData } from 'utils/constant';
import { serviceOptions } from 'utils/constant';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { addDoc, collection, onSnapshot, query, where,updateDoc,doc,getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from "firebase/auth";
import { db,auth } from 'config/firebase';

const serviceTableColumn= ["Service", "Sub Category", "Min Price", "Max Price"]; 

const Services = () => {

  const [serviceTableData, setServiceTableData] = useState([]);
  const [unSubscribe, setUnSubscribe] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalRef = useRef();
  const toast=useToast()
  const [UserId,setUserId]=useState();
  const [errors,setErrors]=useState({
    services:{
        service: "",
        subCategoryService:"",
        minPrice:"",
        maxPrice:""
    }
  });

  const dataSchema=yup.object().shape({
    services:yup.object().shape({
      service: yup.string().required("Service is Required"),
      subCategoryService: yup.string().required("Service is  Required"),
      minPrice: yup.number(),
      maxPrice: yup.number()
    })
  })

  const [formData,setFormData]=useState({
    services:{
        service: "",
        subCategoryService:"",
        minPrice:"",
        maxPrice:""
    }
  });

  useEffect(()=>{
    const unsubscribe=onAuthStateChanged(auth,(user)=>{
      if(user){
        const userid=user.uid;
        setUserId(userid);
      }else{
        console.log("user does not exist in personal section");
      }
    });

    return ()=>unsubscribe();
  },[]);

  useEffect(()=>{
    console.log("service Data:",serviceTableData);
  },[])

  useEffect(() => {
      return () => {
          if (unSubscribe) {
              unSubscribe();
          }
      };
  }, []);

  useEffect(()=>{
    getPartnerService();
  },[UserId]);

  const handleChange=(e)=>{
    const {name,value}=e.target;
    setFormData((prevData)=>({
      ...prevData,services:{...prevData.services,[name]:value}
    }));

    dataSchema.validateAt(`services.${name}`,{...formData,services:{...formData.services,[name]:value}}).then(()=>{
      setErrors((prevErrors)=>({...prevErrors,services:{...prevErrors.services,[name]:''}}));
    }).catch((err)=>{
      setErrors((prevErrors)=>({...prevErrors,services:{...prevErrors.services,[name]:err.message}}));
    });
  }

  const addService = async (e) => {
    e.preventDefault();
    try {
      const profileDocRef = collection(db, "userProfiles");
      const q = query(profileDocRef, where("uid", "==", UserId));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const docRef = doc(db, "userProfiles", docId);
        await updateDoc(docRef, {
          services: [...serviceTableData, formData.services], 
        });
  
        setServiceTableData([...serviceTableData, formData.services]);
  
        toast({
          title: "Service Added Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top", 
          variant: "solid",
          backgroundColor: "white",
          containerStyle: {
            backgroundColor: "white", 
            border: "1px solid #38A169", 
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: "bold",
            padding: "3px",
            boxShadow: "lg",
            color: "#2D3748", 
            boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
          },
        })
  
        onClose();
        setFormData({
          services: {
            service: "",
            subCategoryService: "",
            minPrice: "",
            maxPrice: "",
          },
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  

  const getPartnerService = async () => {
    try {
        const partnerUid =  UserId;
        const queryForGetService = query(collection(db, "userProfiles"), where("uid", "==", partnerUid));
        const subScribe = onSnapshot(queryForGetService, (snapshot) => {
            const services = snapshot.docs.map((doc, index) => {
                console.log(doc.data());
                return ({
                    ...doc.data().services,
                    srNo: index + 1,
                    id: doc.id,
                })
            });
            setServiceTableData(services);
        });
        setUnSubscribe(() => subScribe)
    } catch (error) {
        console.log(error.message)
    }
}


  return (
    <Box>
      <Flex justifyContent='flex-end' mb='3'>
        <Button
          colorScheme='blue'
          onClick={onOpen}
        >
          + Add Service
        </Button>
      </Flex>
      <TableContainer backgroundColor='white' borderRadius='2rem' h='20rem'>
        <Table size='sm'>
          <Thead h='10' backgroundColor='#D7E9FD'>
            <Tr>
              {serviceTableColumn?.map((item, index) => (
                <Th key={index} textAlign='center'>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {serviceTableData.map((item, index) => (
              <Tr key={index}>
                <Td textAlign='center'>{item?.service}</Td>
                <Td textAlign='center'>{item?.subCategoryService}</Td>
                <Td textAlign='center'>{item?.maxPrice}</Td>
                <Td textAlign='center'>{item?.minPrice}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Add Service Modal */}
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent backgroundColor='#EBF2FA'>
          <ModalHeader>Add Service</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={addService}>
          <ModalBody>
              <Text mb='2'>Service</Text>
              <Select
                mb='4'
                placeholder='Select option'
                backgroundColor="white"
                value={formData.services.service}
                onChange={handleChange}
                name="service"
              >
                {
                  serviceOptions.map((item, index) => {
                    return (
                      <option value={item.value} key={index}>{item?.label}</option>
                    )
                  })
                }
              </Select>
              {subCategoryServicesData?.filter((service) => formData.services.service in service).length > 0 && (
                <FormControl>
                  <Text mb='2'>Service Subcategory</Text>
                  <Select
                    mb='4'
                    placeholder='Select Service Category'
                    backgroundColor="white"
                    name="subCategoryService"
                    value={formData.services.subCategoryService}
                    onChange={handleChange}
                  >
                    {
                      subCategoryServicesData
                        .filter((service) => formData.services.service in service)
                        .map((option, index) => (option[formData.services.service]))
                        .flat()
                        .map((category, index) => (
                          <option key={index} value={category?.value}>{category?.label}</option>
                        ))}

                  </Select>
                </FormControl>
              )}
              <FormControl
                w="100%%"
                mb='4'
              >
                <FormLabel htmlFor='minPrice'>Min Price</FormLabel>
                <Input
                  id="minPrice"
                  style={{ backgroundColor: "white" }}
                  name="minPrice"
                  type="number"
                  value={formData.services.minPrice}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl
                mb='4'
                w="100%"
              >
                <FormLabel htmlFor='minPrice'>Max Price</FormLabel>
                <Input
                  id="maxPrice"
                  style={{ backgroundColor: "white" }}
                  name="maxPrice"
                  type="number"
                  value={formData.services.maxPrice}
                  onChange={handleChange}
                />
              </FormControl>
              <Text>Please Note : Min Price and Max Prices are optional to fill.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' type="submit">
              Save
            </Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Services

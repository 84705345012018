"use client"

import { Box, Flex, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import Personal from "./components/personal"
import BusinessInfo from "./components/businessInfo"
import Bank from "./components/bank"
import Verification from "./components/verification"
import Services from "./components/services"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { storage } from "config/firebase"
import { onAuthStateChanged } from "firebase/auth"
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { db } from "config/firebase"
import { Spinner } from "react-bootstrap"
import useUserDisplayName from "hooks/useUserDisplayName"
import { auth } from "config/firebase"
import Personal_icon from "../partnerProfile/assets/Personal.png"
import Businessinfo_icon from "../partnerProfile/assets/Business_Info.png"
import Bank_icon from "../partnerProfile/assets/Bank.png"
import Verification_icon from "../partnerProfile/assets/Verification.png"
import Services_icon from "../partnerProfile/assets/Services.png"
import Arrow_icon from "../partnerProfile/assets/ooui_next-ltr.png"
import { useMediaQuery } from "@mui/material"
import { clientDB } from "config/firebase"
import maleProfilePic from "./assets/maleProfileImage.png"
import femaleProfilePic from "./assets/femaleProfileImage.png"
import userIcon from "./assets/4957136.jpg"

const Profile = () => {
  const profilePicInputRef = useRef(null)
  const [profilePicture, setProfilePicture] = useState("")
  const [partnerId, setPartnerId] = useState("")
  const [UserId, setUserId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [partnerData, setPartnerData] = useState({})
  const { displayName } = useUserDisplayName(db, auth)
  const toast = useToast()
  const [personalPopup, setPersonalPopup] = useState(false)
  const [businessInfoPopup, setBusinessInfoPopup] = useState(false)
  const [verificationPopup, setVerificationPopup] = useState(false)
  const [bankPopup, setBankPopup] = useState(false)
  const [servicesPopup, setServicesPopup] = useState(false)
  const isMobile = useMediaQuery("(max-width:640px)")
  const isSmallMobile = useMediaQuery("(max-width:400px)")
  const [profileCompletionPercentage, setProfileCompletionPercentage] = useState(0)

  // Calculate profile completion percentage
  useEffect(() => {
    if (!partnerData) return

    let completedSections = 0
    const totalSections = 4 // personal, business, bank, verification

    // Check if personal section is completed
    if (
      partnerData.personal &&
      partnerData.personal.firstName &&
      partnerData.personal.lastName &&
      partnerData.personal.email &&
      partnerData.personal.phone
    ) {
      completedSections++
    }

    // Check if business section is completed
    if (
      partnerData.businessInfo &&
      partnerData.businessInfo.businessName &&
      partnerData.businessInfo.gstNumber &&
      partnerData.businessInfo.budget && // Ensure budget exists in formData
      partnerData.businessInfo.commission // Ensure commission exists in formData
    ) {
      completedSections++;
    }

    // Check if bank section is completed
    if (
      partnerData.bank &&
      partnerData.bank.accountNumber &&
      partnerData.bank.bankName &&
      partnerData.bank.ifscNumber
    ) {
      completedSections++
    }

    // Check if verification section is completed
    if (partnerData.verification && partnerData.verification.aadhaar && partnerData.verification.pan) {
      completedSections++
    }

    // Calculate percentage
    const percentage = Math.round((completedSections / totalSections) * 100)
    setProfileCompletionPercentage(percentage)
  }, [partnerData])

  const handlePersonalPopup = () => {
    if (personalPopup === false) {
      setPersonalPopup(true)
    } else {
      setPersonalPopup(false)
    }
  }

  const handleBusinessInfoPopup = () => {
    if (businessInfoPopup === false) {
      setBusinessInfoPopup(true)
    } else {
      setBusinessInfoPopup(false)
    }
  }

  const handleBankPopup = () => {
    if (bankPopup === false) {
      setBankPopup(true)
    } else {
      setBankPopup(false)
    }
  }

  const handleServicesPopup = () => {
    if (servicesPopup === false) {
      setServicesPopup(true)
    } else {
      setServicesPopup(false)
    }
  }

  const handleVerificationPopup = () => {
    if (verificationPopup === false) {
      setVerificationPopup(true)
    } else {
      setVerificationPopup(false)
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userid = user.uid
        setUserId(userid)
      } else {
        console.log("user does not exist in personal section")
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    fetchUserData()
  }, [UserId])

  const fetchUserData = async () => {
    try {
      if (UserId) {
        const ProfileDocRef = collection(db, "userProfiles");
        const q = query(ProfileDocRef, where("uid", "==", UserId));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          setPartnerData(data); // Set the complete partner data
  
          // Set profile picture logic (same as client panel)
          if (data.profileImage) {
            setProfilePicture(data.profileImage);
          } else {
            setProfilePicture(maleProfilePic);
          }
        } else {
          console.log("User does not exist!");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    console.log("The file is:", file)
    if (!file) {
      return
    }

    const storageRef = ref(storage, `profilePictures/${file.name}`)
    await uploadBytes(storageRef, file)

    // Get the download URL for the uploaded file
    const downloadUrl = await getDownloadURL(storageRef)
    console.log("downloadUrl of Image:", downloadUrl)

    const imgUrlObj = { profileImage: downloadUrl }
    const ProfileDocRef = collection(db, "userProfiles")
    const chatCollectionRef = collection(clientDB, "Chats")
    const q = query(ProfileDocRef, where("uid", "==", UserId))
    const chatQuery = query(chatCollectionRef, where("partnerId", "==", UserId))
    const documentSnap = await getDocs(q)
    const chatDocumentSnap = await getDocs(chatQuery)

    if (!chatDocumentSnap.empty) {
      const docIds = chatDocumentSnap.docs.map((item) => item.id)
      docIds.forEach(async (docId) => {
        const docRef = doc(chatCollectionRef, docId)
        await updateDoc(docRef, { partnerProfileImage: downloadUrl })
      })
    }

    if (!documentSnap.empty) {
      const docId = documentSnap.docs[0].id
      const docRef = doc(ProfileDocRef, docId)
      await updateDoc(docRef, imgUrlObj)
      toast({
        title: "Profile Picture Updated Successfully!",
        description: "The User Profile Picture was succesfully updated!",
        status: "success",
        duration: "2500",
        isClosable: true,
      })
    }

    fetchUserData()

    // Optionally, clear the input field after successful upload
    event.target.value = ""
  }

  return (
    <div style={{ marginTop: window.innerWidth < 768 ? "3.6rem" : "unset" }} className="d-flex flex-row mb-3">
      {isLoading && (
        <Flex justifyContent={"center"}>
          <Spinner size="xl" marginTop={"5rem"} backdropBlur="blur" />
        </Flex>
      )}
      <Flex alignItems="center" flexDirection={isMobile ? "column" : "row"}>
        <Box>
        <label for="actual-btn" style={{ cursor: "pointer" }}>
              <div style={{ borderRadius: "100%", height: "150px", width: "150px", overflow: "hidden" }}>
                <img src={profilePicture || "/placeholder.svg"} style={{ height: "100%", width: "100%" }} />
              </div>
            </label>
            <input type="file" id="actual-btn" hidden onChange={handleFileChange} />
          <Input
            id="image"
            name="image"
            type="file"
            className="hidden"
            ref={profilePicInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </Box>

        <Flex ml="1rem" flexDirection="column">
          <Text fontSize="2rem" fontWeight="bold">
            {partnerData?.firstName ?? ""} {partnerData?.middleName ?? ""} {partnerData?.lastName ?? ""}
          </Text>
          {displayName && (
            <Text fontSize={isMobile ? "18px" : "2rem"} fontWeight="bold">
              {displayName}
            </Text>
          )}
          <Text
            fontSize={isMobile ? "12px" : "16px"}
            color={profileCompletionPercentage === 100 ? "green" : "blue"}
          >
            Profile Completion : {profileCompletionPercentage}%
          </Text>
          <Box w="100%" maxW="200px" mt={1} mb={2}>
            <Box w="100%" h="8px" bg="gray.200" borderRadius="full">
              <Box
                h="100%"
                w={`${profileCompletionPercentage}%`}
                bg={profileCompletionPercentage === 100 ? "green" : "blue"}
                borderRadius="full"
                transition="width 0.5s ease-in-out, background-color 0.3s ease-in-out"
              />
            </Box>

          </Box>
        </Flex>
      </Flex>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "max-content",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{ boxShadow: "0 1px 0 gray", height: "1px", width: "88%", marginTop: "20px", marginBottom: "20px" }}
          ></div>
          <div
            style={{
              display: "flex",
              height: "max-content",
              width: "90%",
              alignItems: "center",
              padding: "2px",
              marginBottom: "10px",
            }}
          >
            <img src={Personal_icon || "/placeholder.svg"} alt="personal_icon" style={{ marginRight: "40px" }} />{" "}
            <p style={{ marginRight: (isSmallMobile && "90px") || (isMobile && "140px") }}>Personal</p>{" "}
            <img
              src={Arrow_icon || "/placeholder.svg"}
              alt="arrow_icon"
              style={{ transform: personalPopup ? "rotate(90deg)" : "none", cursor: "pointer" }}
              onClick={handlePersonalPopup}
            />
          </div>
          {personalPopup ? (
            <div style={{ width: "100%" }}>
              <Personal />
            </div>
          ) : (
            <div></div>
          )}

          <div
            style={{
              display: "flex",
              height: "max-content",
              width: "90%",
              alignItems: "center",
              padding: "2px",
              marginBottom: "10px",
            }}
          >
            <img
              src={Businessinfo_icon || "/placeholder.svg"}
              alt="businessInfo_icon"
              style={{ marginRight: "40px" }}
            />{" "}
            <p style={{ marginRight: (isSmallMobile && "50px") || (isMobile && "100px"), minWidth: "100px" }}>
              Business Info
            </p>{" "}
            <img
              src={Arrow_icon || "/placeholder.svg"}
              alt="arrow_icon"
              style={{ transform: businessInfoPopup ? "rotate(90deg)" : "none", cursor: "pointer" }}
              onClick={handleBusinessInfoPopup}
            />
          </div>
          {businessInfoPopup ? (
            <div style={{ width: "100%" }}>
              <BusinessInfo />
            </div>
          ) : (
            <div></div>
          )}

          <div
            style={{
              display: "flex",
              height: "max-content",
              width: "90%",
              alignItems: "center",
              padding: "2px",
              marginBottom: "10px",
            }}
          >
            <img src={Bank_icon || "/placeholder.svg"} alt="businessInfo_icon" style={{ marginRight: "40px" }} />{" "}
            <p style={{ marginRight: (isSmallMobile && "50px") || (isMobile && "100px"), minWidth: "100px" }}>Bank</p>{" "}
            <img
              src={Arrow_icon || "/placeholder.svg"}
              alt="arrow_icon"
              style={{ transform: bankPopup ? "rotate(90deg)" : "none", cursor: "pointer" }}
              onClick={handleBankPopup}
            />
          </div>
          {bankPopup ? (
            <div style={{ width: "100%" }}>
              <Bank />
            </div>
          ) : (
            <div></div>
          )}

          <div
            style={{
              display: "flex",
              height: "max-content",
              width: "90%",
              alignItems: "center",
              padding: "2px",
              marginBottom: "10px",
            }}
          >
            <img
              src={Verification_icon || "/placeholder.svg"}
              alt="businessInfo_icon"
              style={{ marginRight: "40px" }}
            />{" "}
            <p style={{ marginRight: (isSmallMobile && "50px") || (isMobile && "100px"), minWidth: "100px" }}>
              Verification
            </p>{" "}
            <img
              src={Arrow_icon || "/placeholder.svg"}
              alt="arrow_icon"
              style={{ transform: verificationPopup ? "rotate(90deg)" : "none", cursor: "pointer" }}
              onClick={handleVerificationPopup}
            />
          </div>
          {verificationPopup ? (
            <div style={{ width: "100%" }}>
              <Verification />
            </div>
          ) : (
            <div></div>
          )}

          <div
            style={{
              display: "flex",
              height: "max-content",
              width: "90%",
              alignItems: "center",
              padding: "2px",
              marginBottom: "10px",
            }}
          >
            <img src={Services_icon || "/placeholder.svg"} alt="businessInfo_icon" style={{ marginRight: "40px" }} />{" "}
            <p style={{ marginRight: (isSmallMobile && "50px") || (isMobile && "100px"), minWidth: "100px" }}>
              Services
            </p>{" "}
            <img
              src={Arrow_icon || "/placeholder.svg"}
              alt="arrow_icon"
              style={{ transform: servicesPopup ? "rotate(90deg)" : "none", cursor: "pointer" }}
              onClick={handleServicesPopup}
            />
          </div>
          {servicesPopup ? (
            <div style={{ width: "100%" }}>
              <Services />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <div style={{ marginTop: "2rem" }}>
          <Tabs variant="soft-rectangle" colorScheme="#FFFFFF">
            <TabList ml="4" mr="3">
              <Tab backgroundColor="#FFFFFF" mr="6" borderRadius="8px" paddingInline={"5"}>
                Personal
              </Tab>
              <Tab backgroundColor="#FFFFFF" mr="6" borderRadius="8px" paddingInline={"5"}>
                Business Info
              </Tab>
              <Tab backgroundColor="#FFFFFF" mr="6" borderRadius="8px" paddingInline={"5"}>
                Bank
              </Tab>
              <Tab backgroundColor="#FFFFFF" mr="6" borderRadius="8px" paddingInline={"5"}>
                Verification
              </Tab>
              <Tab backgroundColor="#FFFFFF" mr="6" borderRadius="8px" paddingInline={"5"}>
                Services
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Personal />
              </TabPanel>
              <TabPanel>
                <BusinessInfo />
              </TabPanel>
              <TabPanel>
                <Bank />
              </TabPanel>
              <TabPanel>
                <Verification />
              </TabPanel>
              <TabPanel>
                <Services />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      )}
    </div>
  )
}

export default Profile


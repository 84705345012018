// Chakra imports
import { Box, useDisclosure, useBreakpointValue } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
import Header from "components/header/Header";
// Layout components

import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarResponsive } from "components/sidebar/Sidebar";

import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const isMobile = useBreakpointValue({ sm: true, xl: false });
  const [isCollapse, setIsCollapse] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/partner/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Looks like you are in the wrong page";
    for (let i = 0; i < routes.length; i++) {
      if(routes[i].layout === "/project"){
        return routes[i].path;
      }
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          console.log("route.index:",window.location.href.indexOf(routes[i].layout + routes[i].path));
          return routes[i].name;
        }
    }
    return activeRoute;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/partner") {
        return (
          <Route
            exact path={prop.layout + prop.path}
            component={prop.component}
            key={key} 
          />
        );
      }

      if(prop.layout === "/project"){
        return(
          <Route
            exact path={prop.mainLayout + prop.layout + prop.path}
            component={prop.component}
            key={key} 
          />
        )
      }else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";

  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
          style={{
            borderRadius: "2rem",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          {isMobile ? (
            <SidebarResponsive
              routes={routes}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />
          ) : (
            <Sidebar
              routes={routes}
              {...rest}
              isCollapse={isCollapse}
              setIsCollapse={setIsCollapse}
            />
          )}
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{
              base: "100%",
              xl: isCollapse ? "calc(100% - 115px)" : "calc(100% - 260px)",
            }}
            maxWidth={{
              base: "100%",
              xl: isCollapse ? "calc(100% - 115px)" : "calc(100% - 260px)",
            }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Box padding="1rem" paddingBlock={'0px'}>
              <Header onOpen={onOpen} brandText={getActiveRoute(routes)} />
            </Box>

            {getRoute() ? (
              <Box
                mx="auto"
                p={{ base: "20px", md: "30px" }}
                pe="20px"
                minH="100vh"
              >
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/" to="/partner/home" />
                </Switch>
              </Box>
            ) : null}
            <Box>
              <Footer />
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}

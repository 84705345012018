import { Flex, Avatar, Text } from "@chakra-ui/react";

const EventCard = ({ clientName, serviceName, time, profilePic }) => {
  return (
    <Flex
      p="1rem"
      boxShadow="-3px 0px 1px 0px #407BFF"
      borderRadius="20px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex alignItems="center">
        <Avatar
          src={profilePic}
          alt="Profile Pic"
          boxSize={{ base: "40px", md: "50px", lg: "60px" }}
        />
        <Flex ml="1rem" flexDirection="column">
          <Text fontSize={{ base: "1rem", md: "1.1rem", lg: "1.2rem" }} fontWeight="bold">
            {clientName}
          </Text>
          <Text fontSize={{ base: "0.8rem", md: "0.9rem", lg: "1rem" }} fontWeight="medium">
            {serviceName}
          </Text>
        </Flex>
      </Flex>
      <Flex ml="auto" alignItems="center">
        <Text fontSize={{ base: "0.8rem", md: "0.9rem", lg: "1rem" }} fontWeight="medium" textAlign="right">
          {time}
        </Text>
      </Flex>
    </Flex>
  );
};

export default EventCard;

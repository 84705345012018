import { Avatar, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'

const EnquiryTable = ({ column, row }) => {
    return (
        <TableContainer>
            <Table>
                <Thead backgroundColor="#D0E3F3">
                    <Tr>
                        {column.map((item, index) => {
                            return (
                                <Th key={index}>{item}</Th>
                            )
                        })}
                    </Tr>
                </Thead>
                <Tbody backgroundColor="#EBF2FA">
                            <Tr>
                                <Td>{row?.EnquiryDetails?.category}</Td>
                                <Td>{row?.EnquiryDetails?.projectStart}</Td>
                                <Flex justifyContent="center" alignItems="center">
                                    <Avatar w="2rem" h="2rem" />
                                    <Td pl="1">{row?.EnquiryDetails?.fullName}</Td>
                                </Flex>
                                <Td>{row?.EnquiryDetails?.priceRange[0]}-{row?.EnquiryDetails?.priceRange[1]}</Td>
                                <Td>I.T.</Td>
                                <Td>{row?.EnquiryDetails?.projectTimeline}</Td>
                                <Td>{row?.EnquiryDetails?.status}</Td>
                                <Td>{row?.EnquiryDetails?.projectStart}</Td>
                            </Tr>
                </Tbody>
                {/* <Tbody backgroundColor="#EBF2FA">
                    {Array.isArray(row) ? (
                        row.map((item, index) => (
                            <Tr key={index}>
                                <Td>{item?.EnquiryDetails?.category}</Td>
                                <Td>{item?.EnquiryDetails?.projectStart}</Td>
                                <Flex justifyContent="center" alignItems="center">
                                    <Avatar w="2rem" h="2rem" />
                                    <Td pl="1">{item?.EnquiryDetails?.fullName}</Td>
                                </Flex>
                                <Td>{item?.EnquiryDetails?.priceRange[0]}-{item?.EnquiryDetails?.priceRange[1]}</Td>
                                <Td>I.T.</Td>
                                <Td>{item?.EnquiryDetails?.projectTimeline}</Td>
                                <Td>{item?.EnquiryDetails?.status}</Td>
                                <Td>{item?.EnquiryDetails?.projectStart}</Td>
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Td>{row?.EnquiryDetails?.category}</Td>
                            <Td>{row?.EnquiryDetails?.projectStart}</Td>
                            <Flex justifyContent="center" alignItems="center">
                                <Avatar w="2rem" h="2rem" />
                                <Td pl="1">{row?.EnquiryDetails?.fullName}</Td>
                            </Flex>
                            <Td>{row?.EnquiryDetails?.priceRange[0]}-{row?.EnquiryDetails?.priceRange[1]}</Td>
                            <Td>I.T.</Td>
                            <Td>{row?.EnquiryDetails?.projectTimeline}</Td>
                            <Td>{row?.EnquiryDetails?.status}</Td>
                            <Td>{row?.EnquiryDetails?.projectStart}</Td>
                        </Tr>
                    )}
                </Tbody> */}

            </Table>
        </TableContainer>
    )
}

export default EnquiryTable;
import {
    Avatar,
    Box,
    Button,
    Flex,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Select,
    Tabs,
    Text,
    Textarea,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Menu,
    MenuButton,
    MenuList,
    MenuGroup,
    MenuItem,
    useToast,
    VStack,
    Grid,
    useBreakpointValue
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { ColumnData } from './data/QuotePriceData';
import { RowData } from './data/QuotePriceData';
import EnquiryTable from './component/EnquiryTable';
import { useParams, Redirect } from 'react-router-dom/cjs/react-router-dom';
import { useLocation } from "react-router-dom";
import { collection, getDocs, query, doc, where, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import * as yup from "yup";
import { useFormik } from 'formik';
import { auth, db } from "../../../config/firebase"
import { dynamicInputs, timeLineArray } from './data/dynamicInputData';
import i_Button from '../../../assets/img/quotePrice/material-symbols_info-outline.png'
import { nanoid } from "nanoid"
import { infoSectionData } from './data/dynamicInputData';
import { allFeaturesData, postServiceData } from './data/dynamicInputData';
import { IoIosArrowDown } from "react-icons/io";

const QuotePrice = () => {
    const [columnData, setColumnData] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [partnerData, setPartnerData] = useState();
    const { enquiryID } = useParams();
    const location = useLocation();
    const [paymentRatioField, setPaymentRatioField] = useState([""]); // Initially one box
    const [paymentStructure, setPaymentStructure] = useState("");
    const [userId, setUserId] = useState();
    const [serviceName, setServiceName] = useState();
    const infoSectionRef = useRef();
    const IButtonRef = useRef();
    const [displayInfoSection, setDisplayInfoSection] = useState(false);
    const [menuGroupOpen, setMenuGroupOpen] = useState(null);
    const [selectedItems, setSelectedItems] = useState({});
    const [postServiceSelectedItems, setPostServiceSelectedItems] = useState([])
    const [KeyFeatures, setKeyFeatures] = useState([]);
    const [btnClicked, setBtnClicked] = useState(false);
    const toast = useToast();


    const generateEnquiryId = () => {
        return nanoid(16);
    }

    const [formData, setFormData] = useState({
        bidID: generateEnquiryId(),
        price: "",
        timeline: "",
        startDate: "",
        revisions: "",
        postServiceSupport: "",
        paymentStructure: "",
        paymentRatio: [50, 25, 25],
        desiredCommission: 20,
    })

    function handleClosingInfoSection(e) {
        if (infoSectionRef.current && IButtonRef.current && !infoSectionRef.current.contains(e.relatedTarget) && !IButtonRef.current.contains(e.relatedTarget)) {
            setDisplayInfoSection(false);
        }

    }

    const handlePostServiceCheckboxChange = (item) => {
        const currentArray = postServiceSelectedItems || [];
        const updatedArray = currentArray.includes(item) ? currentArray.filter((i) => i !== item) : [...currentArray, item];
        setPostServiceSelectedItems(updatedArray)
    }



    const handleMenuCheckboxChange = (category, item) => {
        setSelectedItems((prev) => {
            const currentArray = prev[category] || [];
            const updatedArray = currentArray.includes(item) ? currentArray.filter((i) => i !== item) : [...currentArray, item];
            return { ...prev, [category]: updatedArray }
        })
    }


    useEffect(() => {
        setColumnData(ColumnData);
        const currentUser = auth.currentUser;
        if (currentUser) {
            setUserId(currentUser.uid);
        }

    }, []);

    const createInputBoxes = (value) => {
        if (value >= 1 && value <= 7) {
            setPaymentRatioField(new Array(value).fill(""));
        }
    };

    const handlePaymentStructureChange = (e) => {
        let value = e.target.value;

        // Allow only numbers 1-7
        if (value === "" || (Number(value) >= 1 && Number(value) <= 7)) {
            setPaymentStructure(value);
            createInputBoxes(Number(value));
        }
    };

    const handlePaymentRatioChange = (e, index) => {
        const newValues = [...paymentRatioField];
        newValues[index] = e.target.value;
        setPaymentRatioField(newValues);
    };

    const handleKeyFeatureChange = (e, index) => {
        const value = e.target.value;
        const updatedValues = [...KeyFeatures];
        updatedValues[index] = value;
        setKeyFeatures(updatedValues)
    }

    useEffect(() => {
        getEnquiry()
    }, [userId]);

    useEffect(() => {
        setServiceName(rowData?.EnquiryDetails?.category);
    }, [rowData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevdata) => ({ ...prevdata, [name]: value }));
    }

    const handleSlider = (val) => {
        setFormData((prevData) => ({ ...prevData, desiredCommission: val }))
    }

    const handleDataChange = (e) => {
        const date = e.target.value;
        let dateString = date + "";
        const dateArray = dateString.split("-")
        dateArray.reverse()
        dateString = dateArray.join("-");
        setFormData((prevData) => ({ ...prevData, startDate: dateString }))
    }


    const sendWhatsAppMessage = async (phoneNumber, name,quotationlink) => {
        try {
          console.log(phoneNumber);
          const response = await fetch("http://localhost:5000/api/whatsappmessage/send-message", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
    
              phone: phoneNumber, // Pass the phone number directly
              params: [name, quotationlink],
              message_name: "form_submission"
            }),
          });
    
          const data = await response.json();
          console.log("WhatsApp API Response:", data);
        } catch (error) {
          console.error("Error sending WhatsApp message:", error);
        }
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalEnquiryData = {
            EnquiryDetails: rowData.EnquiryDetails,
        }

        const partnerBiddingDetails = {
            partnerDetails: partnerData,
            biddingDetails: {
                ...formData,
                paymentRatio: paymentRatioField.join("-"),
                postServiceSupportFor: postServiceSelectedItems,
                keyFeatures: KeyFeatures,
                allFeatures: selectedItems
            }
        }
        setBtnClicked(true);
        try {
            const collectionRef = collection(db, "biddingData");
            const enquiryQuery = query(collectionRef, where("EnquiryDetails.enquiryID", "==", enquiryID));
            const querySnapshot = await getDocs(enquiryQuery);
            
            if (querySnapshot.empty) {
                const addedDoc = await addDoc(collectionRef, finalEnquiryData);
                const partnerCollectionRef = collection(db, `biddingData/${addedDoc.id}/partners`);
                await addDoc(partnerCollectionRef, partnerBiddingDetails);
            } else {
                const partnerCollectionRef = collection(db, `biddingData/${querySnapshot.docs[0].id}/partners`)
                await addDoc(partnerCollectionRef, partnerBiddingDetails);
            }
            toast({
                title: "Bidding form submitted successfully",
                description: "Bidding form sent to client successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top", 
                variant: "solid",
                backgroundColor: "white",
                containerStyle: {
                  backgroundColor: "white", 
                  border: "1px solid #38A169", 
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "3px",
                  boxShadow: "lg",
                  color: "#2D3748", 
                  boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
                },
              })
        } catch (error) {
            console.log(error);
        }
        
    }

    const getEnquiry = async () => {
        try {
            const collectionRef = collection(db, "assignedLead");
            const q = query(collectionRef, where("EnquiryDetails.enquiryID", "==", enquiryID));
            const snapshot = await getDocs(q);
            if (!snapshot.empty) {
                const docId = snapshot.docs[0].id;
                const partnerCollectionRef = collection(db, `assignedLead/${docId}/partners`);
                const partnersQuery = query(partnerCollectionRef, where("partnerId", "==", userId))
                const partners = await getDocs(partnersQuery)
                if (!partners.empty) {
                    setRowData(snapshot.docs[0].data());
                    setPartnerData(partners.docs[0].data());
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const RequiredFeatures = [
        { value: "Responsive Design", checked: false },
        { value: "Custom UI/UX Design", checked: false },
        { value: "Dark Mode Support", checked: false },
        { value: "Custom Animations", checked: false },
        { value: "Accessibility Compliance", checked: false },
        { value: "Static Website Development", checked: false },
        { value: "Dynamic Website Development", checked: false },
        { value: "CMS Integration (WordPress, Webflow, etc.)", checked: false },
        { value: "E-commerce Integration (Shopify, WooCommerce, etc.)", checked: false },
        { value: "API Integration", checked: false },
        { value: "Payment Gateway Integration", checked: false },
        { value: "User Authentication & Login System", checked: false },
        { value: "Admin Panel / Dashboard", checked: false },
        { value: "Contact Forms & Lead Capture", checked: false },
        { value: "Live Chat Integration", checked: false },
        { value: "Newsletter Subscription", checked: false },
        { value: "Push Notifications", checked: false },
        { value: "Custom Functionalities", checked: false },
        { value: "Fast Loading Speed", checked: false },
        { value: "Performance Optimization", checked: false },
        { value: "Security Features (SSL, Firewall, etc.)", checked: false },
        { value: "Backup & Restore", checked: false },
        { value: "Database Integration", checked: false },
        { value: "SEO Optimization (On-Page SEO, Schema, etc.)", checked: false },
        { value: "Blog Setup", checked: false },
        { value: "Social Media Integration", checked: false },
        { value: "Analytics & Tracking Setup", checked: false },
        { value: "Custom Landing Pages", checked: false },
        { value: "Stock Images & Icons", checked: false },
        { value: "Custom Graphics & Illustrations", checked: false },
        { value: "Copywriting for Website Page", checked: false },
        { value: "Other", checked: false },
    ];

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [serviceDetails, setServiceDetails] = useState({
        featuresRequired: [],
    });

    const [featuresData, setFeaturesData] = useState(RequiredFeatures);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const handleFeaturesCheckboxChange = (e) => {
        const { value, checked } = e.target;

        setFeaturesData((prevData) =>
            prevData.map((item) =>
                item.value === value ? { ...item, checked } : item
            )
        );

        setServiceDetails((prevData) => ({
            ...prevData,
            featuresRequired: checked
                ? [...prevData.featuresRequired, value]
                : prevData.featuresRequired.filter((item) => item !== value),
        }));
    };

    const handleServiceDetails = (e) => {
        const { name, value } = e.target;
        setServiceDetails((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const gridColumns = useBreakpointValue({ base: "repeat(2, 127px)", md: "repeat(5, 1fr)" });
    const thumbSize = useBreakpointValue({ base: 4, md: 5 }); // Larger on mobile
    const trackHeight = useBreakpointValue({ base: "10px", md: "6px" });
    const textMargin = useBreakpointValue({ base: "42px", md: "35px" });

    //     const [selectedEnquiry, setSelectedEnquiry] = useState(null);

    //   useEffect(() => {
    //     const enquiry = JSON.parse(localStorage.getItem("selectedEnquiry"));
    //     setSelectedEnquiry(enquiry);
    //   }, []);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Text mb="1rem" fontSize="1.3rem" color="#455A64" fontWeight="bold">Enquiry</Text>
                <EnquiryTable
                    column={columnData}
                    row={rowData}
                />
                {/* {selectedEnquiry ? (
                    <EnquiryTable column={columnData} row={selectedEnquiry} />
                ) : (
                    <p>No enquiry selected</p>
                )} */}
                <Text m="1rem 0 1rem .9rem" fontSize="1.3rem" color="#455A64" fontWeight="bold">Quote Price</Text>
                <Flex>
                    <Tabs variant='unstyle' w="100%">
                        <TabList>
                            {/* <Tab ml="1rem" border="1px solid" borderRadius="1rem" borderColor="#76767e" bgColor="#FFFFFF">Basic Details</Tab> */}
                            <Tab ml='.9rem' border="1px solid" borderRadius=".3rem" borderColor="#76767e" bgColor="#FFFFFF">All Features</Tab>
                        </TabList>
                        <TabPanels >
                            <TabPanel >
                                <Box h="max-content" p={{ base: "0.6rem 0.6rem", md: "0.8rem 1.5rem" }} border="1px solid" borderRadius="0.5rem" borderColor="#D9D9D9" bgColor="inherit">
                                    <div style={{ display: 'grid', gridTemplateColumns: gridColumns, gap: '1.3rem' }}>
                                        <FormControl>
                                            <FormLabel color="#455A64" htmlFor='price'>Price</FormLabel>
                                            <Input name='price' id='price' type='number' placeholder='₹9999' onChange={handleChange} border="1px solid" borderColor="#76767e" borderRadius="0.3rem" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel color="#455A64" htmlFor='timeline'>Timeline</FormLabel>
                                            <Select name='timeline' id='timeline' placeholder='Select' onChange={handleChange} border="1px solid" borderColor="#76767e" borderRadius="0.3rem">
                                                {timeLineArray.map((item, index) => <option key={index}>{item}</option>)}
                                            </Select>
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel color="#455A64" htmlFor='startDate'>Start Date</FormLabel>
                                            <Input name='startDate' id='startDate' type='date' onChange={handleDataChange} border="1px solid" borderColor="#76767e" borderRadius="0.3rem" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel color="#455A64" htmlFor='revisions'>Revisions</FormLabel>
                                            <Input name='revisions' id='revisions' type='number' placeholder='3' onChange={handleChange} border="1px solid" borderColor="#76767e" borderRadius="0.3rem" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel color="#455A64" htmlFor='postServiceSupport'>Post Service Support</FormLabel>
                                            <Select name='postServiceSupport' id='postServiceSupport' placeholder='Select' onChange={handleChange} border="1px solid" borderColor="#76767e" borderRadius="0.3rem">
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Select>
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel color="#455A64" htmlFor='paymentStructure'>Payment Structure</FormLabel>
                                            <Input name='paymentStructure' id='paymentStructure' type='number' placeholder='1' value={paymentStructure} onChange={handlePaymentStructureChange} border="1px solid" borderColor="#76767e" borderRadius="0.3rem" min="1" max="7" />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel color="#455A64" htmlFor='paymentRatio'>Payment Ratio</FormLabel>
                                            <Flex gap='.3rem' flexWrap="wrap">
                                                {paymentRatioField.map((item, index) => (
                                                    <Input key={index} w="43px" name='paymentRatio' id='paymentRatio' type='text' value={item} onChange={(e) => handlePaymentRatioChange(e, index)} border="1px solid" borderColor="#76767e" borderRadius="0.3rem" />
                                                ))}
                                            </Flex>
                                        </FormControl>

                                        <FormControl w="100%">
                                            <FormLabel color="#455A64" htmlFor="desiredCommission" textAlign="start">Desired Commission</FormLabel>

                                            <Flex width="100%" justifyContent="space-between" alignItems="center" mb="0.5rem">
                                                <Text border="1px solid" borderColor="#76767e" borderRadius="0.2rem" fontSize="0.9rem" px="8px" py="4px" minW="50px" textAlign="center">10%</Text>
                                                <Text border="1px solid" borderColor="#76767e" borderRadius="0.2rem" fontSize="0.9rem" px="8px" py="4px" minW="50px" textAlign="center">50%</Text>
                                            </Flex>

                                            <Slider
                                                min={10}
                                                max={50}
                                                defaultValue={20}
                                                step={1}
                                                onChange={handleSlider}
                                                w={{ base: "90%", md: "100%" }}
                                                minW="130px"
                                            >
                                                <SliderTrack bg="gray.200" h={trackHeight} borderRadius="full">
                                                    <SliderFilledTrack bg="blue.500" />
                                                </SliderTrack>
                                                <SliderThumb boxSize={thumbSize} bg="blue.500">
                                                    <Text mt={textMargin} fontSize={{ base: "sm", md: "xs" }}>
                                                        {formData.desiredCommission}%
                                                    </Text>
                                                </SliderThumb>
                                            </Slider>
                                        </FormControl>

                                        <FormControl isDisabled={formData.postServiceSupport !== "Yes"}>
                                            <FormLabel color="#455A64" htmlFor='postServiceTimeline'>P.S. Timeline</FormLabel>
                                            <Input name='postServiceTimeline' id='postServiceTimeline' type='text' placeholder='eg: 7 Days' onChange={handleChange} border="1px solid" borderColor="#76767e" borderRadius="0.3rem" />
                                        </FormControl>

                                        <FormControl isDisabled={formData.postServiceSupport !== "Yes"}>
                                            <FormLabel color="#455A64" htmlFor='postServiceSupportFor'>If Yes, Post Support</FormLabel>
                                            <Menu id="postServiceSupportFor" name="postServiceSupportFor" closeOnSelect={false}>
                                                <MenuButton border="1px solid" borderColor="#76767e" borderRadius="0.3rem" as={Button}>Select Items</MenuButton>
                                                <MenuList>
                                                    {postServiceData.map((item) => (
                                                        <MenuItem key={item}>
                                                            <Checkbox isChecked={postServiceSelectedItems?.includes(item)} onChange={() => handlePostServiceCheckboxChange(item)}>
                                                                {item}
                                                            </Checkbox>
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Menu>
                                        </FormControl>

                                    </div>


                                    <FormControl mt="2rem">
                                        {/* Key Features Label and Tooltip */}
                                        <Flex direction="column" alignItems="start">
                                            <Flex alignItems="center">
                                                <FormLabel color='#1B2559' marginBottom="0px" htmlFor='keyFeatures'>
                                                    Key Features
                                                </FormLabel>
                                                <img
                                                    ref={IButtonRef}
                                                    src={i_Button}
                                                    alt="i button"
                                                    style={{ height: "15px", width: "15px", marginLeft: "5px", cursor: "pointer" }}
                                                    onMouseEnter={() => setDisplayInfoSection(true)}
                                                    onMouseLeave={handleClosingInfoSection}
                                                />
                                            </Flex>

                                            {/* Tooltip Info Section */}
                                            <div ref={infoSectionRef}
                                                onMouseLeave={handleClosingInfoSection}
                                                style={{
                                                    visibility: displayInfoSection ? "visible" : "hidden",
                                                    opacity: displayInfoSection ? "1" : "0",
                                                    position: "absolute",
                                                    backgroundColor: "white",
                                                    top: "-125px",
                                                    zIndex: "10",
                                                    left: "100px",
                                                    padding: "10px",
                                                    boxShadow: "1px 1px 5px 1px gray",
                                                    transition: "visibility 0.2s ease, opacity 0.2s ease",
                                                    borderRadius: "20px 20px 20px 0px"
                                                }}>
                                                <p>Some key Features:</p>
                                                <div style={{ display: "flex", flexDirection: "column", maxHeight: "100px", overflowY: "auto" }}>
                                                    {infoSectionData.map((item, index) => (
                                                        <p key={index} style={{ fontSize: "10px" }}>{item}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </Flex>

                                        <Text color='#1B2559' fontSize="sm" textAlign="start">
                                            Please note: you can only write a maximum of 20 characters in each box.
                                        </Text>

                                        <Flex className='key-inputs' flexDirection="column" alignItems="start" gap="1rem" mt="1rem">

                                            <Flex
                                                className='row1-ip'
                                                gap={{ base: "1rem", md: "4rem" }}
                                                flexWrap="wrap"
                                                justifyContent="center"
                                            >
                                                <Input
                                                    name='keyFeatures'
                                                    id='keyFeatures-0'
                                                    value={KeyFeatures[0] || ""}
                                                    type='text'
                                                    maxLength={20}
                                                    onChange={(e) => handleKeyFeatureChange(e, 0)}
                                                    border="1px solid"
                                                    borderColor="#76767e"
                                                    borderRadius="0.3rem"
                                                    width={{ base: "calc(50% - 0.5rem)", md: "300px" }}
                                                />
                                                <Input
                                                    name='keyFeatures'
                                                    id='keyFeatures-1'
                                                    value={KeyFeatures[1] || ""}
                                                    type='text'
                                                    maxLength={20}
                                                    onChange={(e) => handleKeyFeatureChange(e, 1)}
                                                    border="1px solid"
                                                    borderColor="#76767e"
                                                    borderRadius="0.3rem"
                                                    width={{ base: "calc(50% - 0.5rem)", md: "300px" }}
                                                />
                                            </Flex>

                                            <Flex
                                                className='row1-ip'
                                                gap={{ base: "1rem", md: "4rem" }}
                                                flexWrap="wrap"
                                                justifyContent="center"
                                            >
                                                <Input
                                                    name='keyFeatures'
                                                    id='keyFeatures-2'
                                                    value={KeyFeatures[2] || ""}
                                                    type='text'
                                                    maxLength={20}
                                                    onChange={(e) => handleKeyFeatureChange(e, 2)}
                                                    border="1px solid"
                                                    borderColor="#76767e"
                                                    borderRadius="0.3rem"
                                                    width={{ base: "calc(50% - 0.5rem)", md: "300px" }}
                                                />
                                                <Input
                                                    name='keyFeatures'
                                                    id='keyFeatures-3'
                                                    value={KeyFeatures[3] || ""}
                                                    type='text'
                                                    maxLength={20}
                                                    onChange={(e) => handleKeyFeatureChange(e, 3)}
                                                    border="1px solid"
                                                    borderColor="#76767e"
                                                    borderRadius="0.3rem"
                                                    width={{ base: "calc(50% - 0.5rem)", md: "300px" }}
                                                />
                                            </Flex>
                                        </Flex>

                                    </FormControl>

                                    <Text color='#1B2559' mt="1rem">Best Practices</Text>
                                    <Text
                                        className='please-text'
                                        color='#1B2559'
                                        w={{ base: "100%", md: "40%" }}
                                        fontSize={{ base: "0.7rem", md: "0.8rem" }}
                                        textAlign="start"
                                    >
                                        Please give the best payment options to our users. Your payment is always safe.
                                        Try to give exclusive prices to our users, better timelines, and deliver the best quality work.
                                    </Text>

                                    <Flex mt="20px" flexDirection="column" justifyContent="center">

                                        <Text color='#263238'>Service Highlights</Text>

                                        <Flex className='service-ip' gap="1.2rem" mt=".5rem" flexWrap={{ base: "nowrap", md: "wrap" }} w="100%">
                                            <Grid
                                                w="100%"
                                                templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, auto)" }}
                                                gap={{ base: "1rem", md: "1.2rem" }}
                                            >
                                                {/* Used Tech */}
                                                <FormControl className='form-page-tech-domain' w="100%" position="relative">
                                                    <FormLabel color="#455A64" htmlFor='usedTech'>Used Tech</FormLabel>
                                                    <Select
                                                        w="100%"
                                                        name='usedTech'
                                                        id='usedTech'
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            e.target.style.opacity = '1';
                                                        }}
                                                        border="1px solid"
                                                        borderColor="#76767e"
                                                        borderRadius="0.3rem"
                                                        opacity="0.5"
                                                        cursor='pointer'
                                                    >
                                                        {[
                                                            "WordPress", "Wix", "Shopify", "Magento", "WooCommerce", "Webflow", "Joomla", "Drupal",
                                                            "React.js", "Vue.js", "Angular", "Next.js", "Laravel", "Django", "Node.js", "PHP",
                                                            "Python", "Ruby on Rails", ".NET", "Flutter Web", "HTML/CSS/JS"
                                                        ].map((tech, index) => (
                                                            <option key={index} value={tech}>{tech}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                {/* Domain/Hosting Included */}
                                                <FormControl className='form-page-tech-domain' w="100%">
                                                    <FormLabel color="#455A64" htmlFor='domainHosting'>Domain/Hosting Included</FormLabel>
                                                    <Select
                                                        w="100%"
                                                        name='domainHosting'
                                                        id='domainHosting'
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            e.target.style.opacity = '1';
                                                        }}
                                                        border="1px solid"
                                                        borderColor="#76767e"
                                                        borderRadius="0.3rem"
                                                        opacity="0.5"
                                                        cursor='pointer'
                                                    >
                                                        {[
                                                            "Domain & Hosting Included", "Only Hosting Included", "Only Domain Included",
                                                            "Assistance in Setting Up (User Purchases Separately)", "Not Included"
                                                        ].map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                {/* No of Pages */}
                                                <FormControl className='form-page-tech-domain' w="100%" position="relative">
                                                    <FormLabel color="#455A64" htmlFor='noOfPages'>No of Pages</FormLabel>
                                                    <Select w="100%" name="noOfPages" id="noOfPages" border="1px solid" borderColor="#76767e" borderRadius="0.3rem" opacity="0.5" cursor="pointer">
                                                        {['1-5', '6-10', '11-20', '21+', 'Unsure'].map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                {/* Features Required */}
                                                <Box w="100%" position="relative">
                                                    <label style={{ color: "#455A64" }} htmlFor="featuresRequired" className="formLabels mb-2">
                                                        Features Required
                                                    </label>

                                                    <Box id="dropdown3" className="relative">
                                                        <Box
                                                            w='100%'
                                                            onClick={toggleDropdown}
                                                            className="bg-[#18141f05] p-2 cursor-pointer rounded flex justify-between items-center"
                                                        >
                                                            <Flex
                                                                mt=".49rem"
                                                                p="0.4rem .7rem"
                                                                alignItems="center"
                                                                fontSize="1rem"
                                                                justifyContent="space-between"
                                                                cursor="pointer"
                                                                border="1px solid"
                                                                borderColor="#76767e"
                                                                borderRadius="0.3rem"
                                                                opacity="0.6"
                                                                w="100%"

                                                            >
                                                                <span style={{ color: "#4B5563" }}>
                                                                    {serviceDetails.featuresRequired.length > 0
                                                                        ? serviceDetails.featuresRequired.join(", ")
                                                                        : "Responsive Design, Dark Mode, Admin Panel"}
                                                                </span>

                                                                <IoIosArrowDown
                                                                    style={{
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        flexShrink: 0,
                                                                    }}
                                                                    className={`transform transition-transform ${dropdownOpen ? "rotate-180" : ""}`}
                                                                />
                                                            </Flex>
                                                        </Box>

                                                        {dropdownOpen && (
                                                            <Flex
                                                                flexDirection="column"
                                                                border="1px solid"
                                                                borderColor="gray.300"
                                                                borderRadius="md"
                                                                h="163px"
                                                                overflowY="auto"
                                                                mt="1"
                                                                p="1"
                                                                style={{ zIndex: 10 }}
                                                                ref={dropdownRef}
                                                            >
                                                                {featuresData.map((option, idx) => (
                                                                    <div key={idx}>

                                                                        {[0, 5, 18, 23, 28].includes(idx) && (
                                                                            <p style={{
                                                                                fontWeight: "600",
                                                                                paddingLeft: "4px",
                                                                                textAlign: "start",
                                                                                fontSize: "16px",
                                                                                marginTop: "8px",
                                                                                marginBottom: "8px",
                                                                                color: "#374151",
                                                                            }}>
                                                                                {idx === 0 ? "Design & User Experience" :
                                                                                    idx === 5 ? "Functionality & Features" :
                                                                                        idx === 18 ? "Performance & Security" :
                                                                                            idx === 23 ? "SEO & Marketing" :
                                                                                                "Creative & Content Support"}
                                                                            </p>
                                                                        )}

                                                                        {/* Feature Options */}
                                                                        <label
                                                                            className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                padding: "4px",
                                                                                gap: "10px",
                                                                                color: "gray-100",
                                                                                fontSize: "15px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                value={option.value}
                                                                                checked={option.checked}
                                                                                onChange={handleFeaturesCheckboxChange}
                                                                                className="mr-2"
                                                                                onClick={(e) => e.stopPropagation()}
                                                                            />
                                                                            {option.value}

                                                                            {option.value === "Other" && (
                                                                                <input
                                                                                    type="text"
                                                                                    name="otherFeature"
                                                                                    onChange={handleServiceDetails}
                                                                                    placeholder="Please specify"
                                                                                    style={{
                                                                                        marginLeft: "3px",
                                                                                        border: "1px solid #ccc",
                                                                                        borderRadius: "4px",
                                                                                        padding: "2px 9px"
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </Flex>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Grid>

                                        </Flex>

                                    </Flex>

                                    <Button
                                        mt="2rem"
                                        type="submit"
                                        p={{ base: "0.6rem 1rem", md: "0 2rem" }}
                                        w={{ base: "100%", md: "auto" }}
                                        bgColor="#1D70D6"
                                        color="#FFFFFF"
                                        borderRadius="0.4rem"
                                        disabled={btnClicked}
                                    >
                                        Send
                                    </Button>

                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Box h="max-content" p="2rem" border="1px solid" borderRadius="0.5rem" borderColor="#D9D9D9" bgColor="inherit">
                                    <Box minHeight="25rem" p="1rem" h="max-content" border="1px solid" borderRadius="0.5rem" borderColor="#D9D9D9" color="#26323873" backgroundColor="#FFFFFF">
                                        <Text>All features- </Text>
                                        <Text>Follow the given the format to convey all your features and proposal to have higher chances of getting work.</Text>
                                        <Text mt="1rem">1.Offering 1 in details</Text>
                                        <Text>2.Offering 2, in details</Text>
                                        <Text>3.Offering 3, in details</Text>
                                        <Text mt="1rem">Mention whatever you can offer apart from what you have mentioned in basic details.</Text>
                                    </Box>
                                    <Button mt="2rem" p="0 2rem" bgColor="#1D70D6" color="#FFFFFF">Send</Button>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Flex>
            </form>
        </>
    )
}

export default QuotePrice

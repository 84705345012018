import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select,useToast } from '@chakra-ui/react'
import { useMediaQuery } from '@mui/material';
import { db,auth } from 'config/firebase';
import { collection, doc,setDoc, getDocs, query, updateDoc, where, QuerySnapshot, addDoc } from 'firebase/firestore';
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { States } from "views/partner/partnerProfile/data/ServiceData";

const Personal = () => {

    const [states, setStates] = useState(States);
    const [partnerDetails,setPartnerDetails]=useState({})
    const isMobile=useMediaQuery('(max-width:640px)');
    const toast = useToast();
    const [UserId,setUserId]=useState();
    const [errors,setErrors]=useState({
        personal:{
            firstName:"",
            middleName:"",
            lastName:"",
            email:"",
            phone:"",
            whatsappNo:"",
            city:"",
            state:""
        }
      });
    
      // const dataSchema=yup.object().shape({
      //   personal:yup.object().shape({
      //     firstName:yup.string().required('This Field is Required!').min(3,"minimum 3 letters").max(20,"max 20 letters"),
      //     middleName:yup.string().required('This Field is Required!').min(3,"minimum 3 letters").max(20,"max 20 letters"),
      //     lastName:yup.string().required('This Field is Required!').min(3,"minimum 3 letters").max(20,"max 20 letters"),
      //     email:yup.string().required('This Field is Required!').email("Must be a valid email"),
      //     phone:yup.string().required('Whatsapp Number Required!'),
      //     whatsappNo:yup.string().required('Whatsapp Number Required!'),
      //     city:yup.string().required('This Field is Required!').min(3,"minimum 3 letters").max(40,"max 20 letters"),
      //     state:yup.string().required('This Field is Required!').min(3,"minimum 3 letters").max(40,"max 20 letters"),
      //   })
      // })

    const [formData,setFormData]=useState({
        personal:{
            firstName:"",
            middleName:"",
            lastName:"",
            email:"",
            phone:"",
            whatsappNo:"",
            city:"",
            state:""
        }
    }
    );

    useEffect(()=>{
        const unsubscribe=onAuthStateChanged(auth,(user)=>{
          if(user){
            const userid=user.uid;
            setUserId(userid);
          }else{
            console.log("user does not exist in personal section");
          }
        });
    
        return ()=>unsubscribe();
      },[]);
    

      useEffect(()=>{
        getData();
      },[UserId]);

    const updateDetails=async()=>{
        try{
          const profileDocRef=collection(db,"userProfiles");
          const q=query(profileDocRef,where("uid","==",UserId));
          const querySnapshot=await getDocs(q);
          if(!querySnapshot.empty){
            const docId=querySnapshot.docs[0].id;
            const docRef=doc(db,"userProfiles",docId);
            await updateDoc(docRef,formData);
            toast({
              title: "Personal details have been saved.",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top", 
              variant: "solid",
              backgroundColor: "white",
              containerStyle: {
                backgroundColor: "white", 
                border: "1px solid #38A169", 
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "bold",
                padding: "3px",
                boxShadow: "lg",
                color: "#2D3748", 
                boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
              },
            })
          }
        }catch(error){
          console.log(error.message);
        }
      }

      const getData=async()=>{
        try{
          const profileDocRef=collection(db,"userProfiles");
          const q=query(profileDocRef,where("uid","==",UserId));
          const querySnapshot=await getDocs(q);
          if(!querySnapshot.empty){
            const data=querySnapshot.docs[0].data();
            console.log("Data:",data);
            const personalData=data.personal;
            setFormData((prevData)=>({
              ...prevData,personal:{
                ...personalData
              }
            }))
          }
        }catch(error){
          console.log(error.message);
          }
        
      }

    const handleChange=(e)=>{
        const {name,value}=e.target;
        setFormData((prevData)=>({
            ...prevData,personal:{
                ...prevData.personal,
                [name]:value
            }
        }))

    //Validating Specific field
    // dataSchema.validateAt(`personal.${name}`,{...formData,personal:{...formData.personal,[name]:value}}).then(()=>{
    //     setErrors((prevErrors)=>({...prevErrors,personal:{...prevErrors.personal,[name]:''}}));
    //   }).catch((err)=>{
    //     setErrors((prevErrors)=>({...prevErrors,personal:{...prevErrors.personal,[name]:err.message}}));
    //   })
     }

    const handleSubmit=async(e)=>{
    e.preventDefault();
    const isFormValid=await formData;
    if(isFormValid){
      updateDetails();
    }else{
      toast({
        title:'Form Submission Failed!',
        description:'There are errors in form.',
        status:'error',
        duration:'2500',
        isClosable:true,
      });
    }
    }

    return (
        <Box w="100%" borderWidth='1px' borderRadius='lg' overflow='hidden' padding="2rem">
            <form onSubmit={handleSubmit}>
                <Flex
                    mb='5'
                    justifyContent="space-between"
                    flexDirection={isMobile?"column":"row"}
                >
                    <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.personal.firstName}>
                        <FormLabel htmlFor='firstName'>First Name <span style={{color:"red"}}>*</span></FormLabel>
                        <Input
                            id="firstName "
                            name="firstName"
                            type="text"
                            onChange={handleChange}
                            value={formData.personal.firstName}
                            style={{ backgroundColor: "white" }}
                            required
                        />
                        <FormErrorMessage>{errors.personal.firstName}</FormErrorMessage>
                    </FormControl>
                    <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.personal.lastName}>
                        <FormLabel htmlFor='lastName'>Last Name</FormLabel>
                        <Input
                            id="lastName"
                            name="lastName"
                            type="text"
                            onChange={handleChange}
                            value={formData.personal.lastName}
                            style={{ backgroundColor: "white" }}
                        />
                    <FormErrorMessage>{errors.personal.lastName}</FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex
                    mb='5'
                    justifyContent="space-between"
                    flexDirection={isMobile?"column":"row"}
                >
                   
                    <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.personal.email}>
                        <FormLabel htmlFor='email'>Email Address <span style={{color:"red"}}>*</span></FormLabel>
                        <Input
                            id="email"
                            name="email"
                            type="text"
                            onChange={handleChange}
                            value={formData.personal.email}
                            style={{ backgroundColor: "white" }}
                            required
                        />
                        <FormErrorMessage>{errors.personal.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.personal.whatsappNo}>
                        <FormLabel htmlFor='whatsappNo'>Whatsapp Number <span style={{color:"red"}}>*</span></FormLabel>
                        <Input
                            id="whatsappNo"
                            name="whatsappNo"
                            type="number"
                            required
                            onChange={handleChange}
                            value={formData.personal.whatsappNo}
                            style={{ backgroundColor: "white" }}
                        />
                    </FormControl>
                </Flex>
                <Flex
                    mb='5'
                    justifyContent="space-between"
                    flexDirection={isMobile?"column":"row"}
                >
                    <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.personal.phone}>
                        <FormLabel htmlFor='phone'>Contact Number</FormLabel>
                        <Input
                            id="phone"
                            name="phone"
                            type="text"
                            onChange={handleChange}
                            value={formData.personal.phone}
                            style={{ backgroundColor: "white" }}
                        />
                        <FormErrorMessage>{errors.personal.phone}</FormErrorMessage>
                    </FormControl>
                    <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.personal.state}>
                        <FormLabel htmlFor='state'>State</FormLabel>
                        <Select
                            mb='4'
                            id='state'
                            name='state'
                            onChange={handleChange}
                            value={formData.personal.state}
                            backgroundColor="white"
                        >
                            {states?.map((item, index) => (
                                <option value={item} key={index}>{item}</option>
                            ))}
                        </Select>
                        <FormErrorMessage>{errors.personal.state}</FormErrorMessage>
                    </FormControl>
                </Flex>
                <Flex
                    mb='5'
                    justifyContent="space-between"
                    flexDirection={isMobile?"column":"row"}
                >
                      <FormControl w={isMobile?"90%":"45%"} isInvalid={!!errors.personal.city}>
                        <FormLabel htmlFor='city'>City</FormLabel>
                        <Input
                            id="city"
                            name="city"
                            type="text"
                            onChange={handleChange}
                            value={formData.personal.city}
                            style={{ backgroundColor: "white" }}
                        />
                        <FormErrorMessage>{errors.personal.city}</FormErrorMessage>
                    </FormControl>
                </Flex>
                <Button
                    colorScheme='blue'
                    type="submit"
                >
                    Save
                </Button>
            </form>
        </Box>
    )
}

export default Personal

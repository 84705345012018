import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { db } from "config/firebase";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { BsClock } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GoBell } from "react-icons/go";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { SlGraph } from "react-icons/sl";
import { TbTag } from "react-icons/tb";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { serviceRequestStatus } from "utils/constant";
import LogoEnquiryDetails from "views/partner/servicesDetail/enquiryDetail/LogoEnquiryDetails";
import WebEnquiryDetails from "views/partner/servicesDetail/enquiryDetail/WebEnquiryDetails";
import noEnquiry from "../assets/img/No_Enquiry.png";
import CardTimeline from "../assets/img/system-uicons_card-timeline.png";
import { CustomerDecisionPriority, Months } from "../data/ProjectData";
import PaginationMobile from "./PaginationMobile";

const EnquiryTable = ({
  columnDataMobile,
  columnData,
  rowData,
  handleTableState,
  userId,
}) => {
  const [openActionModal, setOpenActionModal] = useState(false);
  const [unSubscribe, setUnSubscribe] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const itemsPerPage = 6;
  const [enquiryData, setEnquiryData] = useState(rowData);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentEnquiry, setCurrentEnquiry] = useState({});
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState("13 Apr 2024");
  const [endDate, setEndDate] = useState("20 Apr 2024");
  const [months, setMonths] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState("15 Days");
  const partnerUid = sessionStorage.getItem("uid");
  const [formData, setFormData] = useState({
    question: "",
  });
  const navigatedByClick = { obj: true };
  const [customerDecisionPriority, setCustomerDecisionPriority] = useState([]);
  const [displayDbData, setDisplayDbData] = useState([]);
  console.log(modalData);
  
  useEffect(() => {
    getServiceRequests();
    setCustomerDecisionPriority(CustomerDecisionPriority);
    setMonths(Months);
    return () => {
      if (unSubscribe) {
        unSubscribe();
      }
    };
  }, []);

  const getServiceRequests = async () => {
    try {
      const userQuery = query(collection(db, "quotations"));
      const subScribe = onSnapshot(userQuery, (snapshot) => {
        const serviceRequests = snapshot.docs.map((doc, index) => {
          return {
            ...doc.data(),
            srNo: index + 1,
            id: doc.id,
            name: `${doc.data().userDetails?.firstName}  ${
              doc.data().userDetails?.lastName
            }`,
            email: doc.data().userDetails?.email,
            phone: doc.data().userDetails?.phone,
          };
        });
        setEnquiryData(serviceRequests);
      });
      setUnSubscribe(() => subScribe);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayData = displayDbData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(enquiryData.length / itemsPerPage);

  const handlePrev = () => {
    return setCurrentPage((currPage) => Math.max(currPage - 1, 1));
  };
  const handleNext = () => {
    return setCurrentPage((currPage) => Math.min(currPage + 1, totalPages));
  };

  const viewEnquiryDetails = async (item) => {
    setModalData(item);
    setOpenActionModal(true);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target?.value });
  };

  const sendResponse = async (id) => {
    const serviceRef = doc(db, "quotations", id);
    const partnerResponses = [
      ...currentEnquiry?.partnerResponses,
      {
        question: formData.question,
        partner: partnerUid,
        eStatus: serviceRequestStatus.Pending,
      },
    ];
    await updateDoc(serviceRef, {
      partnerResponses: partnerResponses,
    });
    setOpenActionModal(false);
  };

  const handleDateRange = (dateRange) => {
    if (dateRange.length > 0) {
      setStartDate(
        `${dateRange[0].getDate()} ${
          months[dateRange[0].getMonth() + 1]
        } ${dateRange[0].getFullYear()}`
      );
      setEndDate(
        `${dateRange[1].getDate()} ${
          months[dateRange[1].getMonth() + 1]
        } ${dateRange[1].getFullYear()}`
      );
      const diffRange = Math.ceil(
        (dateRange[1].getTime() - dateRange[0].getTime()) / (1000 * 3600 * 24)
      );
      if (diffRange >= 31) {
        const months = Math.floor(diffRange / 30);
        const days = diffRange % 30;
        if (months > 1 && days > 1) {
          setNumberOfDays(`${months} months ${days} days`);
        } else if (months > 1 && days == 1) {
          setNumberOfDays(`${months} months ${days} day`);
        } else if (months === 1 && days > 1) {
          setNumberOfDays(`${months} month ${days} days`);
        } else if (months === 1 && days === 1) {
          setNumberOfDays(`${months} month ${days} day`);
        }
      } else if (diffRange > 1) {
        setNumberOfDays(`${diffRange} days`);
      } else {
        setNumberOfDays(`${diffRange} day`);
      }
    }
    setOpenCalendar(!openCalendar);
  };

  const handleNotInterested = async () => {
    try {
      const leadsCollectionRef = collection(db, "assignedLead");
      const q = query(
        leadsCollectionRef,
        where(
          "EnquiryDetails.enquiryID",
          "==",
          modalData?.EnquiryDetails?.enquiryID
        )
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const partnerCollectionRef = collection(
          db,
          `assignedLead/${docId}/partners`
        );
        const partnerQuery = query(
          partnerCollectionRef,
          where("partnerId", "==", userId)
        );
        const partner = await getDocs(partnerQuery);
        if (!partner.empty) {
          const partnerDocId = partner.docs[0].id;
          const partnerDocRef = doc(partnerCollectionRef, partnerDocId);
          await updateDoc(partnerDocRef, { confirmationStatus: "rejected" });
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    console.log(rowData);
  }, []);

  return (
    <>
      <Box
        display={{ sm: "none", md: "block" }}
        border="3px solid"
        borderRadius="0.5rem"
        borderColor="#D3D3D3"
      >
        <Flex m="0.3rem 1rem" justifyContent="space-between">
          <Flex alignItems="center" fontWeight="bold">
            <Text
              _hover={{ color: "#1C6ED0" }}
              cursor="pointer"
              onClick={() => handleTableState("newEnquiries")}
            >
              New Enquiries
            </Text>
            <Text _hover={{ color: "#1C6ED0" }} cursor="pointer" ml="1rem">
              Pending
            </Text>
            <Text
              _hover={{ color: "#1C6ED0" }}
              cursor="pointer"
              ml="1rem"
              onClick={() => handleTableState("rejectedEnquiries")}
            >
              Rejected Enquiries
            </Text>
          </Flex>

          {/* Calender Btn  */}
          <Flex
            display={{ sm: "none", md: "flex" }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Img
              src={CardTimeline}
              alt="CardTimeline"
              style={{ width: "30px", height: "30px", marginRight: "0.6rem" }}
            />
            <Text mr="0.6rem">Past {numberOfDays}</Text>
            <Flex position="relative">
              <ChevronDownIcon
                mr="0.6rem"
                fontSize="2rem"
                onClick={() => setOpenCalendar(!openCalendar)}
                cursor="pointer"
              />
              {openCalendar && (
                <Box
                  position="absolute"
                  top="33px"
                  left="-116px"
                  style={{ backgroundColor: "white" }}
                >
                  <Calendar
                    selectRange={true}
                    onChange={handleDateRange}
                    view={"month"}
                    tileContent={<Text color="brand.500"></Text>}
                    prevLabel={
                      <Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />
                    }
                    nextLabel={
                      <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
                    }
                  />
                </Box>
              )}
            </Flex>
            <Text mr="0.6rem">{startDate}</Text>
            <Text>
              <span style={{ marginRight: "0.6rem", fontSize: "0.8rem" }}>
                To
              </span>
              {endDate}
            </Text>
          </Flex>
        </Flex>
        <TableContainer>
          {rowData.length === 0 ? (
            <Flex justifyContent="center" marginY={"1rem"} color="#263238">
              <Box textAlign="center">
                <Image
                  src={noEnquiry}
                  height={{ sm: "180px", md: "200px" }}
                  width={{ sm: "250px", md: "300px" }}
                  opacity="0.7"
                />
                <Text
                  fontWeight="semibold"
                  mt="1rem"
                  fontSize={{ sm: "1rem", md: "1.3rem" }}
                  opacity="0.9"
                >
                  No Enquiries Yet
                </Text>
              </Box>
            </Flex>
          ) : (
            <Table
              variant="simple"
              style={{ borderCollapse: "separate", borderSpacing: "0 1rem" }}
            >
              <Thead mb="1rem">
                <Tr backgroundColor="#D0E3F3">
                  {columnData?.map((item, index) => (
                    <Th
                      padding={{ sm: ".2rem", md: "1rem" }}
                      fontSize={{ sm: ".4rem", md: ".8rem" }}
                      key={index}
                    >
                      {item}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {rowData.map((item, index) => (
                  <Tr
                    key={index}
                    backgroundColor="#EBF2FA"
                    boxShadow="0rem 0.25rem 0.25rem 0rem #00000040"
                  >
                    <Td>
                      <Flex direction="column">
                        <Text fontWeight="bold">
                          {item?.EnquiryDetails?.category}
                        </Text>
                        {/* <Text fontSize="sm">
                          {item?.EnquiryDetails?.subCategory}
                        </Text> */}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex direction="column">
                        <Text fontWeight="bold">
                          {item?.EnquiryDetails?.projectStart}
                        </Text>
                        <Text fontSize="sm">{item?.time}</Text>
                      </Flex>
                    </Td>
                    <Td>{item?.EnquiryDetails?.fullName}</Td>
                    <Td fontWeight="bold">
                      ₹{item?.EnquiryDetails?.priceRange[0]}-
                      {item?.EnquiryDetails?.priceRange[1]}
                    </Td>
                    <Td fontWeight="bold">{item?.EnquiryDetails?.category}</Td>
                    <Td fontWeight="bold">
                      {item?.EnquiryDetails?.projectTimeline}
                    </Td>
                    <Td fontWeight="bold">{item?.EnquiryDetails?.status}</Td>
                    <Td>
                      <Button
                        backgroundColor="#9BC5EF"
                        borderRadius="2rem"
                        cursor="pointer"
                        onClick={() => viewEnquiryDetails(item)}
                      >
                        Action
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>

        {/* Pagination */}

        <Flex
          display={{ sm: "none", md: "flex" }}
          justifyContent="space-between"
          alignItems="center"
          m=".8rem .8rem"
          position={"relative"}
        >
          <Button
            onClick={handlePrev}
            backgroundColor="#9BC5EF"
            border="1px solid #000000"
            borderRadius="5px"
          >
            Back
          </Button>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Button
            onClick={handleNext}
            backgroundColor="#9BC5EF"
            border="1px solid #000000"
            borderRadius="5px"
          >
            Next
          </Button>
        </Flex>
      </Box>

      {/* Enquiries table for mobile  */}
      <Box
        display={{ sm: "block", md: "none" }}
        // border="3px solid #D3D3D3"
        borderRadius="0.5rem"
        marginTop={"3rem"}
        mb={"1rem"}
      >
        <Flex
          fontSize={"13px"}
          padding={".3rem .2rem"}
          alignItems={"center"}
          fontWeight={"600"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          <Flex gap={".5rem"} letterSpacing={".2px"}>
            <Text
              _hover={{
                color: "#1C6ED0",
              }}
              cursor="pointer"
              onClick={() => handleTableState("newEnquiries")}
            >
              New Enquiries
            </Text>
            <Text
              _hover={{
                color: "#1C6ED0",
              }}
              cursor="pointer"
            >
              Pending
            </Text>
            <Text
              _hover={{
                color: "#1C6ED0",
              }}
              cursor="pointer"
              onClick={() => handleTableState("rejectedEnquiries")}
            >
              Rejected Enquiries
            </Text>
          </Flex>

          {/* Calender Btn  */}
          <Flex alignItems="center">
            <Flex
              alignItems={"center"}
              lineHeight={"3"}
              bg={"#D7E9FD"}
              borderRadius={"2xl"}
              paddingRight=".3rem"
              boxShadow="0rem 0.15rem .2rem 0rem #00000040"
            >
              <ChevronDownIcon
                color={"#1C6ED0"}
                fontSize={"1.8rem"}
                onClick={() => setOpenCalendar(!openCalendar)}
                cursor="pointer"
              />
              {openCalendar && (
                <Box
                  position="absolute"
                  zIndex={5}
                  top="33px"
                  left="21px"
                  style={{ backgroundColor: "white" }}
                  fontSize={{ sm: ".8rem", md: "1rem" }}
                  boxSize={{ sm: "16rem", md: "auto" }}
                >
                  <Calendar
                    selectRange={true}
                    onChange={handleDateRange}
                    view={"month"}
                    tileContent={<Text color="brand.500"></Text>}
                    prevLabel={
                      <Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />
                    }
                    nextLabel={
                      <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
                    }
                  />
                </Box>
              )}
              <Text mr={".3rem"} fontSize={".8rem"}>
                {numberOfDays}
              </Text>
              {/* <Img src={CardTimeline} alt="CardTimeline" boxSize={"1.4rem"} /> */}
            </Flex>
          </Flex>
        </Flex>
        <TableContainer>
          {rowData.length === 0 ? (
            <Flex
              justifyContent="center"
              direction={"column"}
              marginY={"1rem"}
              color="#263238"
            >
              <Table
                variant="simple"
                color={"#455A64"}
                fontWeight={"600"}
                style={{ borderCollapse: "separate", borderSpacing: "0 .8rem" }}
              >
                <Thead extAlign={"center"}>
                  <Tr backgroundColor="#D0E3F3">
                    {columnDataMobile?.map((item, index) => (
                      <Th
                        textAlign={"center"}
                        padding=".6rem .5rem"
                        fontSize=".7rem"
                        key={index}
                      >
                        {item}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
              </Table>
              <Box textAlign="center" margin={"auto"}>
                <Image
                  src={noEnquiry}
                  fontWeight="bold"
                  height={{ sm: "180px", md: "200px" }}
                  width={{ sm: "250px", md: "300px" }}
                  opacity="0.7"
                />
                <Text
                  fontWeight="semibold"
                  mt="1rem"
                  fontSize={{ sm: "1rem", md: "1.4rem" }}
                  opacity="0.9"
                >
                  No Enquiries Yet
                </Text>
              </Box>
            </Flex>
          ) : (
            <Table
              variant="simple"
              color={"#455A64"}
              fontWeight={"600"}
              style={{ borderCollapse: "separate", borderSpacing: "0 .8rem" }}
            >
              <Thead extAlign={"center"}>
                <Tr backgroundColor="#D0E3F3">
                  {columnDataMobile?.map((item, index) => (
                    <Th
                      textAlign={"center"}
                      padding=".6rem .5rem"
                      fontSize=".7rem"
                      key={index}
                    >
                      {item}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {rowData.map((item, index) => (
                  <Tr
                    key={index}
                    backgroundColor="#EBF2FA"
                    boxShadow="0rem 0.15rem .2rem 0rem #00000040"
                  >
                    <Td
                      fontSize={".7rem"}
                      padding=".6rem .8rem"
                      minWidth={"unset !important"}
                      width={"2rem"}
                      paddingRight="0px"
                    >
                      <Flex direction="column" lineHeight={"3"}>
                        <Text
                          whiteSpace="normal"
                          color={"#263238"}
                          opacity="0.8"
                          wordWrap="break-word"
                        >
                          {item?.EnquiryDetails?.category}
                        </Text>
                        {/* <Text isTruncated>{item?.time}</Text> */}
                      </Flex>
                    </Td>

                    <Td fontSize={".7rem"} padding=".6rem .8rem">
                      <Flex flexDir={"column"}>
                        <Text lineHeight={"3"}>
                          {item?.EnquiryDetails?.projectStart}
                        </Text>
                      </Flex>
                    </Td>

                    <Td fontSize={".7rem"} padding=".6rem .8rem">
                      <Text>
                        ₹{item?.EnquiryDetails?.priceRange[0]}-
                        {item?.EnquiryDetails?.priceRange[1]}
                      </Text>
                    </Td>

                    <Td fontSize={".7rem"} padding=".6rem .8rem">
                      {item?.EnquiryDetails?.projectTimeline}
                    </Td>

                    <Td padding=".6rem .8rem">
                      <Button
                        backgroundColor="#1C6ED0"
                        borderRadius=".3rem"
                        width={"3rem"}
                        fontSize={".7rem"}
                        height={"1.3rem"}
                        cursor="pointer"
                        color={"#fff"}
                        onClick={() => viewEnquiryDetails(item)}
                      >
                        Action
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Box>

      {rowData.length !== 0 && (
        <PaginationMobile
          currentPage={currentPage}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      )}

      {/* Action Modal Opening */}
      <Modal
        size="xl"
        isOpen={openActionModal}
        onClose={() => {
          setOpenActionModal(!openActionModal);
        }}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="#EBF2FA">
          {modalData?.EnquiryDetails?.category === "Logo Design" ? (
            <ModalBody
              padding={"0px"}
              borderRadius={"20px"}
              overflow={"hidden"}
            >
              <LogoEnquiryDetails
                openActionModal={() => setOpenActionModal(!openActionModal)}
                data={modalData}
              />
            </ModalBody>
          ) : modalData?.EnquiryDetails?.category === "Website Development" ||
            modalData?.EnquiryDetails?.category === "Website Development" ? (
            <ModalBody
              padding={"0px"}
              borderRadius={"20px"}
              overflow={"hidden"}
            >
              <WebEnquiryDetails
                openActionModal={() => setOpenActionModal(!openActionModal)}
                data={modalData}
              />
            </ModalBody>
          ) : (
            <>
              <ModalHeader>{currentEnquiry?.services}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>Details</Text>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%">
                    <Avatar
                      size="sm"
                      src={currentEnquiry?.userDetails?.profile}
                    />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="CustomerName"
                      value={
                        modalData?.EnquiryDetails?.personalDetails?.fullName
                      }
                      disabled={true}
                    />
                  </Flex>
                  <Flex w="50%">
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="SEO"
                      value={
                        modalData?.EnquiryDetails?.categoryDetails?.category
                      }
                      disabled={true}
                    />
                  </Flex>
                </Flex>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%" alignItems="center">
                    <Icon as={SlGraph} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="Banking & Finance"
                      value={
                        modalData?.EnquiryDetails?.categoryDetails?.subCategory
                      }
                      disabled={true}
                    />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon as={FaRegCalendarAlt} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="24 Jan 2024 to 24 Feb 2024"
                      value={
                        modalData?.EnquiryDetails?.serviceDetails?.projectStart
                      }
                      disabled={true}
                    />
                  </Flex>
                </Flex>
                <Text>Pricing</Text>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%" alignItems="center">
                    <Icon as={BsClock} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="2 Weeks"
                      value={
                        modalData?.EnquiryDetails?.projectDetails?.priceRange
                      }
                      disabled={true}
                    />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon as={GoBell} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="Immediately"
                    />
                  </Flex>
                </Flex>
                <Flex pt="1rem" pb="1rem" w="50%" alignItems="center">
                  <Icon as={TbTag} />
                  <Input
                    variant="flushed"
                    placeholder="Banking & Finance"
                    value={
                      modalData?.EnquiryDetails?.projectDetails?.projectTimeline
                    }
                    disabled={true}
                  />
                </Flex>
                <Text pt="0.8rem" pb="0.8rem">
                  Project Requirement
                </Text>
                <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                <Text mt="0.8rem" mb="0.8rem" fontSize="sm">
                  {currentEnquiry?.requirements}
                </Text>
                <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                <Text mt="1rem">Customer Decision Priority</Text>
                <Flex flexDirection="column">
                  {customerDecisionPriority.map((item, index) => {
                    return (
                      <Flex
                        justifyContent="space-between"
                        mt="1rem"
                        index={index}
                      >
                        <Box
                          as="button"
                          w="30%"
                          h="2.5rem"
                          mr="0.5rem"
                          textAlign="center"
                          backgroundColor={item[0].bgColor}
                          color={item[0].color}
                          _hover={{ bg: "#ebedf0", color: "#FFFFFF" }}
                          borderTopLeftRadius="1rem"
                          borderBottomLeftRadius="1rem"
                        >
                          {item[0].text}
                        </Box>
                        <Box
                          as="button"
                          w="30%"
                          h="2.5rem"
                          mr="0.5rem"
                          textAlign="center"
                          backgroundColor={item[1].bgColor}
                          color={item[1].color}
                          _hover={{ bg: "#ebedf0", color: "#FFFFFF" }}
                        >
                          {item[1].text}
                        </Box>
                        <Box
                          as="button"
                          w="30%"
                          h="2.5rem"
                          mr="0.5rem"
                          textAlign="center"
                          backgroundColor={item[2].bgColor}
                          color={item[2].color}
                          _hover={{ bg: "#ebedf0", color: "#FFFFFF" }}
                          borderTopRightRadius="1rem"
                          borderBottomRightRadius="1rem"
                        >
                          {item[2].text}
                        </Box>
                      </Flex>
                    );
                  })}
                </Flex>
                <Text mt="1rem" mb="1rem">
                  Status
                </Text>
                <Flex justifyContent="space-between">
                  <Box
                    as="button"
                    w="25%"
                    h="2.5rem"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#D8F9E6"
                    color="#5DEF92"
                    _hover={{ bg: "#ebedf0", color: "#FFFFFF" }}
                    borderTopLeftRadius="1rem"
                    borderBottomLeftRadius="1rem"
                  >
                    New
                  </Box>
                  <Box
                    as="button"
                    w="25%"
                    h="2.5rem"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#5DEF9233"
                    color="#5DEF92"
                    _hover={{ bg: "#ebedf0", color: "#FFFFFF" }}
                  >
                    Open
                  </Box>
                  <Box
                    as="button"
                    w="25%"
                    h="2.5rem"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#65C756"
                    color="#FFFFFF"
                    _hover={{ bg: "#ebedf0", color: "#FFFFFF" }}
                  >
                    In Progress
                  </Box>
                  <Box
                    as="button"
                    w="25%"
                    h="2.5rem"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#E0E0E0"
                    color="#455A64BF"
                    _hover={{ bg: "#ebedf0", color: "#FFFFFF" }}
                    borderTopRightRadius="1rem"
                    borderBottomRightRadius="1rem"
                  >
                    Closed
                  </Box>
                </Flex>
              </ModalBody>
              <Text ml="1.5rem">Take Action</Text>
              <Flex
                ml="1.5rem"
                mt="1rem"
                mb="1rem"
                justifyContent="flex-start"
                color="#FFFFFF"
              >
                <NavLink
                  to={`/partner/project/quote/${modalData?.EnquiryDetails?.enquiryID}`}
                  state={navigatedByClick}
                  key={modalData?.EnquiryDetails?.enquiryID}
                >
                  <Button backgroundColor="#407BFF">Quote Price</Button>
                </NavLink>

                <Button
                  ml="1rem"
                  backgroundColor="#F28F8F"
                  onClick={handleNotInterested}
                >
                  Not Interested
                </Button>
              </Flex>
              <FormControl mt="1rem" mb="1rem" w="90%" ml="1.5rem">
                <FormLabel htmlFor="question">
                  Have more Questions to ask before you bid?
                </FormLabel>
                <Input
                  id="question"
                  type="text"
                  name="question"
                  placeholder="Type your Question"
                  minHeight="6rem"
                  backgroundColor="#FFFFFF"
                  value={formData?.question}
                  onChange={handleChange}
                />
              </FormControl>
              <ModalFooter>
                <Button
                  backgroundColor="#407BFF"
                  color="#F5F5F5"
                  onClick={() => sendResponse(currentEnquiry?.id)}
                >
                  Send
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnquiryTable;

export const infoSectionData=[
    "Responsive Design",
    "Custom UI/UX",
    "SEO Optimization",
    "Fast Loading Speed",
    "Security Features",
    "E-commerce Ready",
    "CMS Integration",
    "Custom Functionalities",
    "Maintenance & Support"
]

export const postServiceData=[
"Bug Fixes",
"Security Updates",
"Performance Optimization",
"Hosting Assistance",
"Content Updates",
"Regular Backups",
"CMS Training",
"Feature Enhancements",
"SEO Monitoring",
"Server Maintenance",
"Uptime Monitoring",
"Technical Consultation",
]

export const allFeaturesData={
    "Design & User Experience": [
    "Responsive Design",
    "Custom UI/UX Design",
    "Dark Mode Support",
    "Custom Animations",
    "Accessibility Compliance",
  ],
  "Functionality & Features": [
    "Static Website Development",
    "Dynamic Website Development",
    "CMS Integration (WordPress, Webflow, etc.)",
    "E-commerce Integration (Shopify, WooCommerce, etc.)",
    "API Integration",
    "Payment Gateway Integration",
    "User Authentication & Login System",
    "Admin Panel / Dashboard",
    "Contact Forms & Lead Capture",
    "Live Chat Integration",
    "Newsletter Subscription",
    "Push Notifications",
    "Custom Functionalities",
  ],  "Performance & Security": 
  [
    "Fast Loading Speed",
    "Performance Optimization",
    "Security Features (SSL, Firewall, etc.)",
    "Backup & Restore",
    "Database Integration",
  ],
  "SEO & Marketing": [
    "SEO Optimization (On-Page SEO, Schema, etc.)",
    "Blog Setup",
    "Social Media Integration",
    "Analytics & Tracking Setup",
    "Custom Landing Pages",
  ],
  "Creative & Content Support": [
    "Custom Graphics & Illustrations",
    "Stock Images & Icons",
    "Copywriting for Website Pages",
  ],
};

export const dynamicInputs = {
    "Website Development":[
        {
            elementType:"select",
            props:{
                id:"usedTech",
                name:'usedTech',
                placeholder:"select",
                w:"80%"
            },
            text:"Tech Used",
            options:["React.js","Next.js","WordPress"]
        },
        {
            elementType:"select",
            props:{
            id:"hostingIncluded",
            name:'hostingIncluded',
            placeholder:"select",
            w:"80%"
            },
            text:"Domain/Hosting included",
            options:["Yes","No"]
        },
        {
            elementType:"select",
            props:{
            id:"Pages",
            name:'Pages',
            placeholder:"select",
            w:"80%"
            },
            text:"No. of Pages",
            options:[1,2]
        },
    ],    
    "Design":[
        {
            props:{
                type:"text",
                id:"price",
                name:'price',
                placeholder:"₹9999",
                w:"80%"
            },
            text:"Price"
        },
        {
            props:{
            type:"text",
            id:"designTool",
            name:'designTool',
            placeholder:"Tool1",
            w:"80%"
            },
            text:"Design Tool"
        },
        {
            props:{
            type:"text",
            id:"numberOfScreens",
            name:'numberOfScreens',
            placeholder:"2",
            w:"80%"
            },
            text:"Number of screens"
        },
        {
            props:{
            type:"text",
            id:"nameOfScreens",
            name:'nameOfScreens',
            placeholder:"Screen1",
            w:"80%"
            },
            text:"Name of screens"
        },
        {
            props:{
            type:"text",
            id:"allFeatures",
            name:'allFeatures',
            placeholder:"Feature1",
            w:"80%"
            },
            text:"All Features"
        },
    ],    
};

export const timeLineArray=[
    "1 Week",
    "2 Week",
    "1 Month",
    "2 Months"
]
import { Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import React from 'react'
import Chat from './components/Chat'
import QueriesTable from './components/QueriesTable'
import { useMediaQuery } from "usehooks-ts";
import { useState } from 'react'

const Inbox = () => {
  const ismobile = useMediaQuery("(max-width: 449px)");
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <Tabs mt={{ base: "3.8rem", md: "unset" }} position="relative" variant="unstyled">
        <TabList
          w={{ base: "50%", md: "16%" }} 
          ml={{ base: "auto", md: ".2rem" }}
          mr={{ base: "auto", md: "0" }}
          mb={{ base: ".8rem" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="full"
          backgroundColor="white"
          padding="0.1rem"
          boxShadow="md"
          position="relative"
          overflow="hidden"
        >
          
          <div
            style={{
              position: "absolute",
              top: 0,
              left: selectedTab === 0 ? 0 : "50%",
              width: "50%",
              height: "100%",
              backgroundColor: "#1C6ED0",
              borderRadius: selectedTab === 0 ? "20px 0 0 20px" : "0 20px 20px 0",
              transition: "all 0.3s ease-in-out",
            }}
          ></div>

          {["Inbox", "Queries"].map((tab, index) => (
            <Text
              key={index}
              onClick={() => setSelectedTab(index)}
              fontSize={{ base: "0.85rem", md: "1rem" }}
              cursor="pointer"
              fontWeight="800"
              padding={{ base: "0.3rem .8rem", md: "0.6rem 1.2rem" }}
              zIndex={1} 
              color={selectedTab === index ? "white" : "black"}
              transition="all 0.3s"
            >
              {tab}
            </Text>
          ))}
        </TabList>
      </Tabs>

      {selectedTab === 0 ? <Chat /> : <QueriesTable />}

      <Flex></Flex>
    </>
  )
}

export default Inbox

export const DashboardData = [
  {
    name: 'Apoorva Rathi',
    service: 'Digital Marketing',
    startTime: "05:30 PM",
    startDate: "02.02.2024"
  },
  {
    name: 'Thomas Salva',
    service: 'Marketing',
    startTime: "08:30 PM",
    startDate: "12.03.2024"
  },
  {
    name: 'Mr. Roy',
    service: 'Web Development',
    startTime: "12:30 PM",
    startDate: "15.02.2024"
  },
];
export const Cards = [
  {
    project: "Ongoing Projects",
    number: 0,
    bgColor: "#E5E6F9"
  },
  {
    project: "Completed Projects",
    number: 0,
    bgColor: "#FFF9E9"
  },
  {
    project: "Approved Enquiries",
    number: 0,
    bgColor: "#E1F2EF"
  },
  {
    project: "Rejected Enquiries",
    number: 0,
    bgColor: "#F3F9FE"
  },
  {
    project: "New Leads",
    number: 0,
    bgColor: "#E5E1F9"
  },
  {
    project: "Pending Leads",
    number: 0,
    bgColor: "#FFEEF7"
  }
];
export const ChatData = [
  {
    clientName: 'Prashant Sirohi',
    message: 'What would be your price for the service ?',
  },
  {
    clientName: 'Prashant Sirohi',
    message: 'What would be your price for the service ?',
  },
  {
    clientName: 'Prashant Sirohi',
    message: 'What would be your price for the service ?',
  },
  {
    clientName: 'Prashant Sirohi',
    message: 'What would be your price for the service ?',
  },
  {
    clientName: 'Prashant Sirohi',
    message: 'What would be your price for the service ?',
  },
  {
    clientName: 'Prashant Sirohi',
    message: 'What would be your price for the service ?',
  },
];
export const MeetingData = [
{
  clientName:'Apoorva Rathi',
  serviceName:'(Digital Marketing)',
  MeetingDescription:'Meeting regarding marketing project Timeline & Strategy with Partner.',
  time:'5:30 PM',
  date:'02.02.2024',

},
{
  clientName:'Apoorva Rathi',
  serviceName:'(Digital Marketing)',
  MeetingDescription:'Meeting regarding marketing project Timeline & Strategy with Partner.',
  time:'5:30 PM',
  date:'02.02.2024',

},
{
  clientName:'Apoorva Rathi',
  serviceName:'(Digital Marketing)',
  MeetingDescription:'Meeting regarding marketing project Timeline & Strategy with Partner.',
  time:'5:30 PM',
  date:'02.02.2024',

},
{
  clientName:'Apoorva Rathi',
  serviceName:'(Digital Marketing)',
  MeetingDescription:'Meeting regarding marketing project Timeline & Strategy with Partner.',
  time:'5:30 PM',
  date:'02.02.2024',

},
{
  clientName:'Apoorva Rathi',
  serviceName:'(Digital Marketing)',
  MeetingDescription:'Meeting regarding marketing project Timeline & Strategy with Partner.',
  time:'5:30 PM',
  date:'02.02.2024',

},
];
export const EventData = [
  {
    clientName: 'Apoorva Rathi',
    serviceName: '(Digital Marketing )',
    time: '10 : 30 AM',
  },
  {
    clientName: 'Apoorva Rathi',
    serviceName: '(Digital Marketing )',
    time: '10 : 30 AM',
  },
  {
    clientName: 'Apoorva Rathi',
    serviceName: '(Digital Marketing )',
    time: '10 : 30 AM',
  },
  {
    clientName: 'Apoorva Rathi',
    serviceName: '(Digital Marketing )',
    time: '10 : 30 AM',
  },
  {
    clientName: 'Apoorva Rathi',
    serviceName: '(Digital Marketing )',
    time: '10 : 30 AM',
  },
  {
    clientName: 'Apoorva Rathi',
    serviceName: '(Digital Marketing )',
    time: '10 : 30 AM',
  },
  {
    clientName: 'Apoorva Rathi',
    serviceName: '(Digital Marketing )',
    time: '10 : 30 AM',
  },
  {
    clientName: 'Apoorva Rathi',
    serviceName: '(Digital Marketing )',
    time: '10 : 30 AM',
  },
];
// Import the functions you need from the SDKs you need
import { initializeApp,getApps,getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth } from "firebase/auth"
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { clientFirebaseConfig,primaryFirebaseConfig } from "./firebaseConfig";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


// Initialize Firebase
const app = getApps().length ? getApp() : initializeApp(primaryFirebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

//Client config
const clientApp=getApps().find(app => app.name === "client") || initializeApp(clientFirebaseConfig, "client");
const clientAuth=getAuth(clientApp);
const clientDB=getFirestore(clientApp);
const clientStorage=getStorage(clientApp);
export { auth, googleProvider, db, storage, analytics, clientAuth,clientDB,clientStorage};

